import { useMemo } from "react";
import { Params, generatePath, useNavigate, useParams } from "react-router-dom";

import { AccessRequired } from "@m/api/components";
import { Badge, EmptyMessage, Spinner, useConfirmState } from "@m/ui";

import { Overlay } from "@mc/components/Overlay";
import { TabData, Tabs } from "@mc/components/Tabs";
import { FEATURE_FLAGS, PATHS } from "@mc/constants";
import { DetailsLayout } from "@mc/layouts";

import {
  FLAGGED_CHECK_RESOURCES_DOWNLOAD_QUERY,
  useCheckDetails,
} from "../api";
import { useUpdateCheckIgnoreFlag } from "../api/useUpdateCheckIgnoreFlag";
import { CHECK_STATUS, CLOUD_SCORE_MESSAGES } from "../constants";
import { CheckStatusIcon } from "../icons";
import { getFirstSentence, getPillar } from "../utils";

import { CheckDetailsRecommendations } from "./CheckDetailsRecommendations";
import { CheckDetailsSource } from "./CheckDetailsSource";
import { CloudScoreOverlayHeader } from "./CloudScoreOverlayHeader";
import { DatetimeField } from "./DatetimeField";
import { DownloadResourcesButton } from "./DownloadResourcesButton";
import { FlaggedCheckResourcesTable } from "./FlaggedCheckResourcesTable";
import { IgnoreCheckButton } from "./IgnoreCheckButton";
import { IgnoreCheckDialog } from "./IgnoreCheckDialog";
import { OptimizationHistory } from "./OptimizationHistory";

interface CheckDetailsOverlayParams extends Params {
  checkId: string;
  tabName?: "resources" | "optimization-history";
}

export const CheckDetailsOverlay = () => {
  const navigate = useNavigate();
  const { checkId, tabName = "resources" } =
    useParams<CheckDetailsOverlayParams>();

  const ignoreDialog = useConfirmState();

  const {
    data: { check, recommendations },
    error,
    loading,
  } = useCheckDetails(checkId);

  const [update, { loading: ignoreLoading }] = useUpdateCheckIgnoreFlag();

  const handleClose = () => {
    navigate("..");
  };
  const handleTabChange = (tab: TabData) => {
    const path = generatePath(PATHS._CLOUD_SCORE_CHECK_DETAILS_TABNAME, {
      checkId: checkId,
      tabName: tab.name,
    });
    navigate("../" + path);
  };
  const handleUpdateIgnoreFlag = async (ignored: boolean) => {
    const { source, sourceId } = check;

    await update({
      ignored,
      source,
      sourceId,
    });

    ignoreDialog.close();

    handleClose();
  };

  const checkIgnored = check?.ignored;
  const checkStatus = checkIgnored ? CHECK_STATUS.IGNORED : check?.status;
  const isLoading = loading || ignoreLoading;

  const tabsData = useMemo(() => {
    const resourcesTab = {
      label: (
        <div className="flex space-x-1">
          <div>Resources</div>
          {!checkIgnored && (
            <Badge label={check?.totalResources} status="active" strong />
          )}
        </div>
      ),
      name: "resources",
    };
    const optimizationTab = {
      label: "Optimization History",
      name: "optimization-history",
    };

    return [resourcesTab, optimizationTab];
  }, [check?.totalResources, checkIgnored]);

  return (
    <Overlay
      data-testid="check-details-overlay"
      onClose={handleClose}
      open={true}
      title="Check"
    >
      <DetailsLayout
        actions={
          !isLoading &&
          !error && (
            <>
              <DatetimeField label="Last updated" isoTime={check.lastRefresh} />
              <CheckDetailsSource label="Source" title={check.source} />

              <AccessRequired
                feature={FEATURE_FLAGS.CLOUD_SCORE_RECOMMENDATIONS_ALLOW_ACCESS}
              >
                <CheckDetailsRecommendations
                  checkId={checkId}
                  recommendations={recommendations}
                />
              </AccessRequired>

              <hr />

              <div data-testid="check-details-actions" className="space-y-0.5">
                <div className="text-xs font-semibold">Actions</div>
                <DownloadResourcesButton
                  disabled={checkIgnored}
                  id={checkId}
                  name={check.name}
                  totalResources={check.totalResources}
                  query={FLAGGED_CHECK_RESOURCES_DOWNLOAD_QUERY}
                />
                <IgnoreCheckButton
                  disabled={ignoreDialog.isOpen}
                  ignore={!checkIgnored}
                  onClick={ignoreDialog.open}
                />
                {check && ignoreDialog.isOpen && (
                  <IgnoreCheckDialog
                    checkName={check.name}
                    ignore={!checkIgnored}
                    onClose={ignoreDialog.close}
                    onConfirm={handleUpdateIgnoreFlag}
                    open={ignoreDialog.isOpen}
                  />
                )}
              </div>
            </>
          )
        }
      >
        {!isLoading && !error && (
          <>
            <CloudScoreOverlayHeader
              badge={
                <Badge label={getPillar(check.pillar)?.name} className="h-2" />
              }
              description={getFirstSentence(check.description)}
              icon={<CheckStatusIcon status={checkStatus} />}
              name={check.name}
            />
            <Tabs
              activeTabName={tabName}
              className="bg-white pl-3"
              onTabChange={handleTabChange}
              tabsData={tabsData}
            />
            <div className="p-3">
              {tabName === "resources" &&
                (checkIgnored ? (
                  <EmptyMessage
                    message={CLOUD_SCORE_MESSAGES.IGNORED_CHECK}
                    className="!m-0 border-2 border-dashed bg-gray-50 p-5 font-semibold text-gray-400"
                  />
                ) : (
                  <FlaggedCheckResourcesTable
                    checkId={checkId}
                    resourceColumns={check?.flaggedResourceFields}
                  />
                ))}
              {tabName === "optimization-history" && (
                <OptimizationHistory checkId={checkId} />
              )}
            </div>
          </>
        )}

        {!isLoading && error && (
          <div className="flex h-full items-center justify-center text-subdued">
            {CLOUD_SCORE_MESSAGES.CHECK_DETAILS_FAILURE}
          </div>
        )}

        {isLoading && (
          <div className="flex h-full items-center justify-center">
            <Spinner />
          </div>
        )}
      </DetailsLayout>
    </Overlay>
  );
};
