import clsx from "clsx";
import { ComponentType, ReactNode } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import { AccessRequired, AccessRequirements } from "@m/api/components";
import { useAuth } from "@m/login";
import { Badge, TBadgeSize, Tooltip } from "@m/ui";

import { generateCompanyPath } from "@mc/utils/companyPath";

import { useSidebar } from "../contexts";

interface SidebarLinkProps extends NavLinkProps {
  ariaLabel?: string;
  className?: string;
  count?: number;
  feature?: AccessRequirements["feature"];
  icon?: ComponentType<any>;
  isNew?: boolean;
  loading?: boolean;
  matchFeatures?: AccessRequirements["matchFeatures"];
  popupContent?: ReactNode;
  size?: TBadgeSize;
  title: string;
  to: string;
}

export const SidebarLink = ({
  ariaLabel,
  className,
  count,
  feature,
  icon: Icon,
  isNew = false,
  loading = false,
  matchFeatures,
  popupContent,
  size = "default",
  title,
  to = "",
  ...props
}: SidebarLinkProps) => {
  const { user } = useAuth();
  const { closeSidebar } = useSidebar();

  return (
    <AccessRequired feature={feature} matchFeatures={matchFeatures}>
      <NavLink
        aria-label={ariaLabel || `Go to ${title}`}
        className={({ isActive }) =>
          clsx(
            "flex items-center space-x-1 rounded-lg px-1 py-0.5 font-medium transition",
            "hover:bg-neutral-200 hover:bg-opacity-[.6]",
            className,
            {
              "bg-neutral-200 bg-opacity-[.6] font-semibold": isActive,

              "text-xs": size === "small",
              "text-sm": size === "default",
            }
          )
        }
        onClick={closeSidebar}
        to={generateCompanyPath(to, user.company.slug)}
        {...props}
      >
        {({ isActive }) => (
          <>
            <span className="inline-flex grow items-center gap-1">
              {Icon && <Icon className="h-2 w-2 stroke-2" />}
              {title}
            </span>
            {!loading && count !== undefined && (
              <Badge
                aria-label={`${title} Count`}
                label={count}
                size="small"
                strong={isActive}
              />
            )}
            {isNew &&
              (popupContent ? (
                <Tooltip
                  className="w-full p-2"
                  color="light"
                  content={popupContent}
                  side="right"
                >
                  <Badge
                    className="hover:cursor-help"
                    label="New"
                    status="positive"
                    strong
                    size="small"
                  />
                </Tooltip>
              ) : (
                <Badge label="New" status="positive" strong size="small" />
              ))}
          </>
        )}
      </NavLink>
    </AccessRequired>
  );
};
