import {
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ChangeEvent, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AccessRequired } from "@m/api/components";
import {
  EngagementDecisionStatus,
  EngagementMilestoneStatus,
  RiskLogItemStatus,
} from "@m/api/public/types";
import { useAuth } from "@m/login";
import {
  Avatar,
  Badge,
  Banner,
  Button,
  Modal,
  Spinner,
  Textarea,
  Tooltip,
} from "@m/ui";
import {
  dt,
  formatFullDate,
  getMilliseconds,
  toHyphenLowercase,
  toProperCase,
  toTimeLocaleLong,
} from "@m/utils";

import { MUTATIONS, ROLES } from "@mc/constants";
import { EmptyCircleIcon } from "@mc/icons";

import {
  useEngagementDetailItem,
  useUpdateEngagementChecklistItem,
  useUpdateEngagementsDecision,
  useUpdateEngagementsMilestone,
  useUpdateRiskLogItemStatus,
} from "../api";
import {
  ChecklistAction,
  DecisionAction,
  EngagementDetailLevelIndicator,
  EngagementDetailOwner,
  MilestoneAction,
  RiskLogAction,
} from "../components";
import { DETAIL_ITEM_STATUS, DETAIL_ITEM_TYPE } from "../constants";
import {
  EngagementDecisionType,
  EngagementDetailItemType,
  EngagementDetailStatus,
  IEngagementMilestoneItem,
  IEngagementsChecklistItem,
  IRiskLogItem,
} from "../types";
import { getRelativeDate } from "../utils";

export const EngagementsDetailItemViewModal = ({
  detailType,
}: {
  detailType: DETAIL_ITEM_TYPE;
}) => {
  const navigate = useNavigate();

  const { engagementId, milestoneId, actionItemId, riskId, decisionId } =
    useParams<{
      engagementId: string;
      milestoneId: string;
      actionItemId: string;
      riskId: string;
      decisionId: string;
    }>();

  let itemId;
  let ItemDetails;
  switch (detailType) {
    case DETAIL_ITEM_TYPE.MILESTONE:
      itemId = milestoneId;
      ItemDetails = MilestoneDetails;
      break;
    case DETAIL_ITEM_TYPE.ACTION_ITEM:
      itemId = actionItemId;
      ItemDetails = ActionItemDetails;
      break;
    case DETAIL_ITEM_TYPE.RISK:
      itemId = riskId;
      ItemDetails = RiskLogDetails;
      break;
    case DETAIL_ITEM_TYPE.DECISION:
      itemId = decisionId;
      ItemDetails = DecisionDetails;
      break;
    default:
      break;
  }

  const {
    data: { detailItem },
    loading: isLoading,
  } = useEngagementDetailItem(engagementId, {
    detailType,
    itemId,
  });

  const handleClose = () => navigate("..");

  return (
    <Modal
      open={true}
      size="lg"
      header={
        <div className="flex flex-col">
          <Badge
            data-testid={`engagement-${toHyphenLowercase(detailType)}-badge`}
            label={toProperCase(detailType)}
            className="my-auto mb-1.5 w-fit text-subdued"
            size="small"
            strong={false}
          />
          <Modal.Title>{isLoading ? "" : detailItem?.title}</Modal.Title>
        </div>
      }
      menuActions={
        isLoading ? null : (
          <AccessRequired role={ROLES.ENGAGEMENT_MANAGER}>
            <MenuActions
              engagementId={engagementId}
              item={detailItem}
              detailType={detailType}
            />
          </AccessRequired>
        )
      }
      onClose={handleClose}
      aria-label="Engagement Detail Item View Modal"
    >
      {isLoading ? (
        <div className="m-5 flex justify-center">
          <Spinner />
        </div>
      ) : (
        <ItemDetails
          engagementId={engagementId}
          detailItem={detailItem}
          isLoading={isLoading}
          onClose={handleClose}
        />
      )}
    </Modal>
  );
};

const MenuActions = ({
  engagementId,
  item,
  detailType,
}: {
  engagementId: string;
  item: EngagementDetailItemType;
  detailType: DETAIL_ITEM_TYPE;
}) => {
  const menuActions = useMemo(() => {
    switch (detailType) {
      case DETAIL_ITEM_TYPE.ACTION_ITEM: {
        return (
          <ChecklistAction
            actionItem={item as IEngagementsChecklistItem}
            engagementId={engagementId}
          />
        );
      }
      case DETAIL_ITEM_TYPE.RISK: {
        return (
          <RiskLogAction
            riskLogItem={item as IRiskLogItem}
            engagementId={engagementId}
          />
        );
      }
      case DETAIL_ITEM_TYPE.MILESTONE:
        return (
          <MilestoneAction
            milestone={item as IEngagementMilestoneItem}
            engagementId={engagementId}
          />
        );
      case DETAIL_ITEM_TYPE.DECISION:
        return (
          <DecisionAction
            decision={item as EngagementDecisionType}
            engagementId={engagementId}
          />
        );
      default:
        break;
    }
  }, [detailType, item, engagementId]);

  return menuActions;
};

const MilestoneDetails = ({
  engagementId,
  detailItem: milestone,
  isLoading,
  onClose,
}: {
  engagementId: string;
  detailItem: IEngagementMilestoneItem;
  isLoading: boolean;
  onClose: () => void;
}) => {
  const { user } = useAuth();
  const [updateMilestone] = useUpdateEngagementsMilestone(engagementId);

  if (!milestone) {
    return null;
  }

  const {
    id,
    title,
    status,
    percentageComplete,
    dueDate,
    owner,
    tasks,
    noteList,
  } = milestone;

  const handleComplete = () => {
    updateMilestone({
      id,
      title,
      percentageComplete,
      dueDate,
      owner,
      status: EngagementMilestoneStatus.Resolved,
    }).then(() => {
      onClose();
    });
  };

  return (
    <div className="flex flex-col gap-4">
      {isLoading ? (
        <div className="flex flex-col items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-3 text-sm">
            <hr />
            <div data-testid="milestone-status" className="flex">
              <span className="w-1/3 font-semibold">Status</span>
              <StatusBadge status={status} />
            </div>

            <div data-testid="milestone-progress" className="flex">
              <span className="w-1/3 font-semibold">Progress</span>
              <div className="text-subdued">{`${percentageComplete}%`}</div>
            </div>

            <div data-testid="milestone-due-date" className="flex">
              <span className="w-1/3 font-semibold">Due</span>
              <div className="text-subdued">
                {dueDate ? <DueDate dueDate={dueDate} /> : "-"}
              </div>
            </div>

            <div data-testid="milestone-owner" className="flex">
              <span className="w-1/3 font-semibold">Owner</span>
              <EngagementDetailOwner
                owner={owner}
                user={user}
                includeName={true}
                includeIcon={false}
                className="text-subdued"
              />
            </div>
          </div>

          {(noteList.length > 0 || tasks.length > 0) && <hr />}
          <TasksList tasks={tasks} />
          <NotesList notes={noteList} />
        </div>
      )}

      <AccessRequired mutation={MUTATIONS.UPDATE_MILESTONE}>
        {status === EngagementMilestoneStatus.Open && (
          <CompleteButton
            buttonText="Complete milestone"
            onClick={handleComplete}
            isLoading={isLoading}
          />
        )}
      </AccessRequired>
    </div>
  );
};

const ActionItemDetails = ({
  engagementId,
  detailItem: actionItem,
  isLoading,
  onClose,
}: {
  engagementId: string;
  detailItem: IEngagementsChecklistItem;
  isLoading: boolean;
  onClose: () => void;
}) => {
  const { user } = useAuth();
  const [updateChecklistItem] = useUpdateEngagementChecklistItem(engagementId);

  if (!actionItem) {
    return null;
  }

  const { id, title, open, dueDate, owner, noteList } = actionItem;

  const handleComplete = () => {
    updateChecklistItem({
      id,
      title,
      dueDate,
      owner,
      open: false,
    }).then(() => {
      onClose();
    });
  };

  return (
    <div className="flex flex-col gap-4">
      {isLoading ? (
        <div className="flex flex-col items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-3 text-sm">
            <hr />
            <div data-testid="action-item-status" className="flex">
              <span className="w-1/3 font-semibold">Status</span>
              <StatusBadge
                status={
                  open ? DETAIL_ITEM_STATUS.OPEN : DETAIL_ITEM_STATUS.RESOLVED
                }
              />
            </div>

            <div data-testid="action-item-due-date" className="flex">
              <span className="w-1/3 font-semibold">Due</span>
              <div className="text-subdued">
                {dueDate ? <DueDate dueDate={dueDate} /> : "-"}
              </div>
            </div>

            <div data-testid="action-item-owner" className="flex">
              <span className="w-1/3 font-semibold">Owner</span>
              <EngagementDetailOwner
                owner={owner}
                user={user}
                includeName={true}
                includeIcon={false}
                className="text-subdued"
              />
            </div>
          </div>

          {noteList.length > 0 && (
            <>
              <hr />
              <NotesList notes={noteList} />
            </>
          )}
        </div>
      )}

      <AccessRequired mutation={MUTATIONS.UPDATE_ACTION_ITEM}>
        {open && (
          <CompleteButton
            buttonText="Complete action item"
            onClick={handleComplete}
            isLoading={isLoading}
          />
        )}
      </AccessRequired>
    </div>
  );
};

const RiskLogDetails = ({
  engagementId,
  detailItem: riskLogItem,
  isLoading,
  onClose,
}: {
  engagementId: string;
  detailItem: IRiskLogItem;
  isLoading: boolean;
  onClose: () => void;
}) => {
  const { user } = useAuth();
  const [updateRiskLogItem] = useUpdateRiskLogItemStatus(engagementId);

  if (!riskLogItem) {
    return null;
  }

  const {
    id,
    owner,
    status,
    description,
    mitigationStrategy,
    itemType,
    dateNeeded,
    impact,
    probability,
    noteList,
  } = riskLogItem;

  const handleComplete = () => {
    updateRiskLogItem({
      id,
      status: RiskLogItemStatus.Resolved,
    }).then(() => {
      onClose();
    });
  };

  return (
    <div className="flex flex-col">
      {isLoading ? (
        <div className="flex flex-col items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          <div
            data-testid="engagement-risk-description"
            className="text-sm text-subdued"
          >
            {description}
          </div>

          {mitigationStrategy && (
            <Banner
              className="mb-1"
              title="Mitigation Strategy"
              label={mitigationStrategy}
              icon={ClipboardDocumentListIcon}
            />
          )}

          <div className="flex flex-col gap-4 text-sm">
            <hr />
            <div data-testid="risk-status" className="flex">
              <span className="w-1/3 font-semibold">Status</span>
              <StatusBadge status={status} />
            </div>
            <div data-testid="risk-type" className="flex">
              <span className="w-1/3 font-semibold">Type</span>
              <div className=" text-subdued">{toProperCase(itemType)}</div>
            </div>
            <div data-testid="risk-due-date" className="flex">
              <span className="w-1/3 font-semibold">Due</span>
              <div className=" text-subdued">
                {dateNeeded ? <DueDate dueDate={dateNeeded} /> : "-"}
              </div>
            </div>
            <div data-testid="risk-owner" className="flex">
              <span className="w-1/3 font-semibold">Owner</span>
              <EngagementDetailOwner
                owner={owner}
                user={user}
                includeName={true}
                includeIcon={false}
                className=" text-subdued"
              />
            </div>
            <div data-testid="risk-probability" className="flex">
              <span className="w-1/3 font-semibold">Probability</span>
              <div className="flex gap-1">
                <EngagementDetailLevelIndicator
                  itemLevelType="Probability"
                  level={probability}
                />
                <div className=" text-subdued">{toProperCase(probability)}</div>
              </div>
            </div>
            <div data-testid="risk-impact" className="flex">
              <span className="w-1/3 font-semibold">Impact</span>
              <div className="flex gap-1">
                <EngagementDetailLevelIndicator
                  itemLevelType="Impact"
                  level={impact}
                />
                <div className="text-subdued">{toProperCase(impact)}</div>
              </div>
            </div>

            {noteList.length > 0 && (
              <>
                <hr />
                <NotesList notes={noteList} />
              </>
            )}
            <AccessRequired mutation={MUTATIONS.UPDATE_RISK_LOG_ITEM}>
              {status === RiskLogItemStatus.Open && (
                <CompleteButton
                  buttonText="Resolve risk"
                  onClick={handleComplete}
                  isLoading={isLoading}
                />
              )}
            </AccessRequired>
          </div>
        </div>
      )}
    </div>
  );
};

const DecisionDetails = ({
  engagementId,
  detailItem: decision,
  isLoading,
  onClose,
}: {
  engagementId: string;
  detailItem: EngagementDecisionType;
  isLoading: boolean;
  onClose: () => void;
}) => {
  const { user } = useAuth();

  const {
    id,
    title,
    impact,
    owner,
    description,
    latestCompletionNote,
    dueDate,
    completedTime,
    status,
  } = decision;

  const [updateDecision, { loading: isUpdateLoading }] =
    useUpdateEngagementsDecision(engagementId);
  const [isCompletionFormOpen, setIsCompletionFormOpen] = useState(false);
  const [completionNote, setCompletionNote] = useState(
    latestCompletionNote?.content
  );

  if (!decision) {
    return null;
  }

  const handleCompletionFormToggle = () => {
    setIsCompletionFormOpen((prev) => !prev);
  };

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCompletionNote(e.target.value);
  };

  const handleComplete = () => {
    const completedDecision = {
      id,
      title,
      impact,
      owner,
      description,
      dueDate,
      status: EngagementDecisionStatus.Completed,
      latestCompletionNote: {
        id: null,
        content: completionNote,
        updatedAt: null,
        createdAt: null,
      },
    };

    updateDecision(completedDecision).then(() => {
      onClose();
    });
  };

  return (
    <div className="flex flex-col gap-4">
      {isLoading ? (
        <div className="flex flex-col items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div
            data-testid="engagement-decision-description"
            className="text-sm text-subdued"
          >
            {description}
          </div>

          {status === EngagementDecisionStatus.Completed &&
            latestCompletionNote && (
              <Banner
                title="Completion Notes"
                label={latestCompletionNote.content}
                icon={ClipboardDocumentListIcon}
              />
            )}

          <hr />
          <div className="flex flex-col gap-3 text-sm">
            <div data-testid="decision-status" className="flex">
              <span className="w-1/3 font-semibold">Status</span>
              <StatusBadge status={status} />
            </div>
            <div data-testid="decision-owner" className="flex">
              <span className="w-1/3 font-semibold">Owner</span>
              <EngagementDetailOwner
                owner={owner}
                user={user}
                includeName={true}
                includeIcon={false}
                className="text-subdued"
              />
            </div>
            <div data-testid="decision-due-date" className="flex">
              <span className="w-1/3 font-semibold">Due</span>
              <div className="text-subdued">
                {dueDate ? <DueDate dueDate={dueDate} /> : "-"}
              </div>
            </div>
            <div data-testid="decision-impact" className="flex">
              <span className="w-1/3 font-semibold">Impact</span>
              <div className="flex gap-1">
                <EngagementDetailLevelIndicator
                  itemLevelType="Impact"
                  level={impact}
                />
                <div className="text-subdued">{toProperCase(impact)}</div>
              </div>
            </div>
            {completedTime && (
              <div data-testid="decision-completed" className="flex">
                <span className="w-1/3 font-semibold">Completed</span>
                <div className="text-subdued">
                  <CompetedDate closedAt={completedTime} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <AccessRequired mutation={MUTATIONS.UPDATE_ENGAGEMENT}>
        {status === EngagementDecisionStatus.Open &&
          (isCompletionFormOpen ? (
            <div className="flex w-full flex-col gap-1 rounded-lg border border-solid border-zinc-100 bg-zinc-50 p-3">
              <div className="rounded-md bg-white">
                <Textarea
                  aria-label="Completion Notes"
                  autoFocus={true}
                  autogrow
                  value={completionNote}
                  id="completionNotes"
                  name="completionNotes"
                  className="p-1"
                  placeholder="Add decision or impact notes"
                  required={true}
                  rows={3}
                  onChange={handleNoteChange}
                />
              </div>
              <div className="flex gap-1">
                <Button
                  className="w-1/4"
                  onClick={handleCompletionFormToggle}
                  disabled={isLoading || isUpdateLoading}
                  fill="subdued"
                  kind="secondary"
                  aria-disabled={isLoading || isUpdateLoading}
                  iconClassName="stroke-2"
                >
                  Cancel
                </Button>
                <CompleteButton
                  buttonText="Complete decision"
                  onClick={handleComplete}
                  isLoading={isLoading}
                />
              </div>
            </div>
          ) : (
            <CompleteButton
              buttonText="Complete decision"
              onClick={handleCompletionFormToggle}
              isLoading={isLoading}
            />
          ))}
      </AccessRequired>
    </div>
  );
};

const StatusBadge = ({ status }: { status: EngagementDetailStatus }) => {
  switch (status) {
    case DETAIL_ITEM_STATUS.OPEN:
      return <Badge label="Open" status="active" className="text-xs" strong />;
    case DETAIL_ITEM_STATUS.ARCHIVED:
      return (
        <Badge
          label="Archived"
          className="text-xs text-subdued"
          status="default"
        />
      );
    case DETAIL_ITEM_STATUS.RESOLVED:
    case DETAIL_ITEM_STATUS.COMPLETED:
      return <Badge label="Completed" className="text-xs" />;
    default:
      return;
  }
};

const DueDate = ({ dueDate }: { dueDate: string }) => {
  const formattedDate = formatFullDate(dueDate, "LLL d, yyyy");

  const currentDate = dt.now().startOf("day").toISO();
  const isDueToday = dt.fromISO(dueDate).startOf("day").toISO() === currentDate;
  const isOverdue = !isDueToday && currentDate > dueDate;

  return (
    <Tooltip content={formattedDate} asChild>
      <span
        className={clsx(
          "cursor-help whitespace-nowrap border-b-[1px] border-dashed",
          {
            "border-red-400": isOverdue,
            "text-red-500": isOverdue,
          }
        )}
      >
        {getRelativeDate(dueDate)}
      </span>
    </Tooltip>
  );
};

const CompetedDate = ({ closedAt }: { closedAt: string }) => {
  const formattedDate = formatFullDate(closedAt, "LLL d, yyyy hh:mm a");

  return (
    <Tooltip content={formattedDate} asChild>
      <span className={"cursor-help border-b-[1px] border-dashed"}>
        {getRelativeDate(closedAt)}
      </span>
    </Tooltip>
  );
};

const TasksList = ({ tasks }: { tasks }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  return tasks.length > 0 ? (
    <div>
      <div
        data-testid="engagement-detail-tasks"
        className="mb-1 flex gap-1 text-sm font-semibold"
      >
        Sub-tasks
        {isOpen ? (
          <ChevronDownIcon
            className="h-2.5 w-2.5"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        ) : (
          <ChevronUpIcon
            className="h-2.5 w-2.5"
            onClick={() => {
              setIsOpen(true);
            }}
          />
        )}
      </div>
      {isOpen && (
        <div className="mx-1 font-medium text-default">
          {tasks.map((task) => (
            <div key={task.id} className="my-1 inline-flex w-full gap-1">
              {task.complete ? (
                <div className="h-2.5 w-2.5">
                  <CheckCircleIcon
                    aria-label="Resolved"
                    className="h-2.5 w-2.5 text-action"
                  />
                </div>
              ) : (
                <div className="h-2.5 w-2.5">
                  <EmptyCircleIcon
                    aria-label="Resolved"
                    className="text- h-2.5 w-2.5 text-zinc-300"
                  />
                </div>
              )}
              {task.title}
            </div>
          ))}
        </div>
      )}
    </div>
  ) : null;
};

const NotesList = ({ notes }: { notes }) => {
  const sortedNotes = useMemo(() => {
    if (!notes) {
      return [];
    }
    return notes.sort(
      (a, b) => getMilliseconds(b.createdAt) - getMilliseconds(a.createdAt)
    );
  }, [notes]);

  return sortedNotes.length > 0 ? (
    <div className="flex flex-col gap-1">
      <div data-testid="engagement-detail-notes" className="mb-1 font-semibold">
        Notes
      </div>
      {sortedNotes.map((note) => {
        const { id, content, createdAt, createdBy } = note;

        return (
          <div key={id} className="flex gap-2">
            <Avatar
              account={{
                ...createdBy,
              }}
              className="text-sm"
            />
            <div className="min-w-0 grow space-y-0.5">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold">{createdBy?.name}</p>
                <p className="text-xs text-subdued">
                  {toTimeLocaleLong(createdAt)}
                </p>
              </div>
              <div className="text-sm">{content}</div>
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
};

const CompleteButton = ({
  buttonText,
  isLoading,
  onClick,
}: {
  buttonText: string;
  isLoading: boolean;
  onClick: () => void;
}) => {
  return (
    <Button
      className="w-full"
      onClick={onClick}
      disabled={isLoading}
      fill="subdued"
      kind="primary"
      leftIcon={CheckIcon}
      aria-disabled={isLoading}
      iconClassName="stroke-2"
    >
      {buttonText}
    </Button>
  );
};
