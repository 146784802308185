import {
  ArrowRightOnRectangleIcon,
  ArrowUpRightIcon,
  BellIcon,
  BuildingOffice2Icon,
  Cog8ToothIcon,
  NewspaperIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

import { useAuth } from "@m/login";
import { Avatar, Dropdown, DropdownGroup, DropdownItem } from "@m/ui";

import { StaffOnly } from "@mc/components/StaffOnly";
import { PATHS } from "@mc/constants";

export const UserDropdown = () => {
  const { user, logout } = useAuth();

  return (
    <Dropdown
      trigger={
        <Avatar
          account={user}
          size="medium"
          className="cursor-pointer hover:opacity-80"
        />
      }
      direction="bottom-end"
      style={{ minWidth: 150 }}
    >
      <DropdownGroup>
        <DropdownItem icon={UserCircleIcon} to={PATHS.SETTINGS_PROFILE}>
          Profile
        </DropdownItem>
        <DropdownItem icon={BellIcon} to={PATHS.SETTINGS_NOTIFICATIONS}>
          Notifications
        </DropdownItem>
      </DropdownGroup>

      <DropdownGroup>
        <DropdownItem icon={BuildingOffice2Icon} to={PATHS.SETTINGS_USERS}>
          Company Settings
        </DropdownItem>
        <StaffOnly>
          <DropdownItem
            href={`${process.env.REACT_APP_ATLAS_URL}/customers/${user.company.slug}`}
            target="_blank"
            className="flex gap-1"
          >
            <NewspaperIcon className="h-2.5 w-2.5 text-yellow-500" />
            View in Atlas
            <ArrowUpRightIcon className="h-1.5 w-1.5 text-subdued" />
          </DropdownItem>
        </StaffOnly>
      </DropdownGroup>

      <StaffOnly>
        <DropdownGroup>
          <DropdownItem to={PATHS.STAFF} className="flex gap-1">
            <Cog8ToothIcon className="h-2.5 w-2.5 text-yellow-500" />
            Mission Admin
          </DropdownItem>
        </DropdownGroup>
      </StaffOnly>

      <DropdownGroup>
        <DropdownItem icon={ArrowRightOnRectangleIcon} onClick={logout}>
          Log out
        </DropdownItem>
      </DropdownGroup>
    </Dropdown>
  );
};
