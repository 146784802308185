import {
  ArchiveBoxIcon,
  ArrowPathIcon,
  CheckCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { generatePath, useNavigate } from "react-router-dom";

import { AccessRequired } from "@m/api/components";
import {
  ScorecardRecommendation,
  ScorecardRecommendationStatus,
} from "@m/api/public/types";
import { Avatar, Badge, Dropdown, DropdownItem, Timestamp } from "@m/ui";

import { PATHS } from "@mc/constants";

import { RecommendationStatusIcon } from "../icons";

interface Props {
  recommendation: Partial<ScorecardRecommendation> &
    Pick<ScorecardRecommendation, "id">;
  onDelete: (recommendation: Partial<ScorecardRecommendation>) => void;
  onUpdateStatus: (
    recommendationId: string,
    status: ScorecardRecommendationStatus
  ) => void;
  loading: boolean;
}

export const CloudScoreRecommendationListItem = ({
  recommendation,
  onDelete,
  onUpdateStatus,
  loading,
}: Props) => {
  const navigate = useNavigate();

  const {
    id: recommendationId,
    createdBy,
    flaggedResources: { totalCount: flaggedResourceCount },
    scorecardRecStatus,
    title,
    updatedTime,
  } = recommendation;

  const handleClickTitle = () => {
    navigate(
      generatePath(PATHS._CLOUD_SCORE_RECOMMENDATION_DETAILS, {
        recommendationId,
      })
    );
  };
  const handleClickEdit = () => {
    navigate(
      generatePath(PATHS._CLOUD_SCORE_UPDATE_RECOMMENDATION, {
        recommendationId,
      })
    );
  };
  const handleClickArchive = () => {
    onUpdateStatus(recommendationId, ScorecardRecommendationStatus.Archived);
  };
  const handleClickResolve = () => {
    onUpdateStatus(recommendationId, ScorecardRecommendationStatus.Resolved);
  };
  const handleClickReopen = () => {
    onUpdateStatus(recommendationId, ScorecardRecommendationStatus.Active);
  };
  const handleClickDelete = () => {
    onDelete(recommendation);
  };

  const dropdownItemClasses = "flex items-center gap-1";

  return (
    <div
      data-testid="cloud-score-recommendation-list-item"
      className="flex justify-between gap-2"
    >
      <div className="flex items-center gap-1">
        <RecommendationStatusIcon status={scorecardRecStatus} />

        <div className="flex flex-wrap items-center gap-1">
          <div
            onClick={handleClickTitle}
            className={clsx("cursor-pointer font-medium", {
              "!text-gray-300":
                scorecardRecStatus === ScorecardRecommendationStatus.Resolved ||
                scorecardRecStatus === ScorecardRecommendationStatus.Archived,
            })}
          >
            {title}
          </div>

          <div className="flex items-center gap-1">
            {flaggedResourceCount > 0 && (
              <Badge
                label={flaggedResourceCount}
                aria-label="Flagged Resource Count"
                size="small"
                className={clsx({
                  "opacity-60":
                    scorecardRecStatus !== ScorecardRecommendationStatus.Active,
                })}
              />
            )}

            <Badge
              label={recommendation?.scorecardResult?.pillar}
              size="small"
              aria-label="Check Pillar"
              className={clsx({
                "opacity-60":
                  scorecardRecStatus !== ScorecardRecommendationStatus.Active,
              })}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 items-center justify-end gap-1">
        <Timestamp isoTime={updatedTime} longFormat={true} />
        <Avatar account={createdBy} />
        <AccessRequired mutation="updateScorecardRecommendationStatus">
          <Dropdown aria-label="Update Recommendation" flip={true}>
            {scorecardRecStatus === ScorecardRecommendationStatus.Active && (
              <DropdownItem
                className={dropdownItemClasses}
                onClick={handleClickResolve}
                disabled={loading}
              >
                <CheckCircleIcon className="h-2.5 w-2.5" />
                Resolve
              </DropdownItem>
            )}
            {scorecardRecStatus !== ScorecardRecommendationStatus.Active && (
              <DropdownItem
                className={dropdownItemClasses}
                onClick={handleClickReopen}
                disabled={loading}
              >
                <ArrowPathIcon className="h-2.5 w-2.5" />
                Reopen
              </DropdownItem>
            )}
            {scorecardRecStatus !== ScorecardRecommendationStatus.Archived && (
              <DropdownItem
                className={dropdownItemClasses}
                onClick={handleClickArchive}
                disabled={loading}
              >
                <ArchiveBoxIcon className="h-2.5 w-2.5" />
                Archive
              </DropdownItem>
            )}
            <AccessRequired mutation="updateScorecardRecommendation">
              <DropdownItem
                className={dropdownItemClasses}
                onClick={handleClickEdit}
                disabled={loading}
              >
                <PencilIcon className="h-2.5 w-2.5" />
                Edit
              </DropdownItem>
            </AccessRequired>
            <AccessRequired mutation="deleteScorecardRecommendation">
              <DropdownItem
                className={dropdownItemClasses}
                onClick={handleClickDelete}
                disabled={loading}
              >
                <TrashIcon className="h-2.5 w-2.5" />
                Delete
              </DropdownItem>
            </AccessRequired>
          </Dropdown>
        </AccessRequired>
      </div>
    </div>
  );
};
