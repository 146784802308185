import { useEffect } from "react";

import { PATHS } from "@mc/constants";
import { useAwsAccountResources } from "@mc/features/AwsResources/api";

import { SidebarLink } from "./SidebarLink";

const MAX_COUNT = 10000;

export const ResourcesSidebarLinks = () => {
  const {
    data: resourcesData,
    loading: resourcesLoading,
    refetch: refetchResources,
  } = useAwsAccountResources({ maxCount: MAX_COUNT + 1 });

  const truncateMaxCount = (total) => {
    if (total > MAX_COUNT) {
      return "10K+";
    }
    return total;
  };

  useEffect(() => {
    if (!resourcesLoading && Object.keys(resourcesData).length < 1) {
      refetchResources();
    }
  }, [resourcesData, refetchResources, resourcesLoading]);

  const {
    applicationLoadBalancers,
    autoScalingGroups,
    classicLoadBalancers,
    ec2Instances,
    ecsClusters,
    eksClusters,
    elasticacheClusters,
    lambdaFunctions,
    networkLoadBalancers,
    rdsInstances,
    redshiftClusters,
  } = resourcesData;

  return (
    <>
      <SidebarLink
        ariaLabel="Go to EC2 Resources"
        count={truncateMaxCount(ec2Instances?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="EC2 Instances"
        to={PATHS.RESOURCES_EC2}
        end
      />
      <SidebarLink
        ariaLabel="Go to Auto Scaling Groups"
        count={truncateMaxCount(autoScalingGroups?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="Auto Scaling Groups"
        to={PATHS.RESOURCES_ASG}
        end
      />
      <SidebarLink
        ariaLabel="Go to RDS Resources"
        count={truncateMaxCount(rdsInstances?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="RDS Instances"
        to={PATHS.RESOURCES_RDS}
        end
      />
      <SidebarLink
        ariaLabel="Go to Application Load Balancer Resources"
        count={truncateMaxCount(applicationLoadBalancers?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="Application Load Balancers"
        to={PATHS.RESOURCES_ALB}
        end
      />
      <SidebarLink
        ariaLabel="Go to Network Load Balancer Resources"
        count={truncateMaxCount(networkLoadBalancers?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="Network Load Balancers"
        to={PATHS.RESOURCES_NLB}
        end
      />
      <SidebarLink
        ariaLabel="Go to Classic Load Balancer Resources"
        count={truncateMaxCount(classicLoadBalancers?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="Classic Load Balancers"
        to={PATHS.RESOURCES_CLB}
        end
      />
      <SidebarLink
        ariaLabel="Go to EKS Clusters"
        count={truncateMaxCount(eksClusters?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="EKS Clusters"
        to={PATHS.RESOURCES_EKS}
        end
      />
      <SidebarLink
        ariaLabel="Go to Lambda Funtions"
        count={truncateMaxCount(lambdaFunctions?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="Lambda Functions"
        to={PATHS.RESOURCES_LAMBDA}
        end
      />
      <SidebarLink
        ariaLabel="Go to ECS Clusters"
        count={truncateMaxCount(ecsClusters?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="ECS Clusters"
        to={PATHS.RESOURCES_ECS}
        end
      />
      <SidebarLink
        ariaLabel="Go to ElastiCache Clusters"
        count={truncateMaxCount(elasticacheClusters?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="ElastiCache Clusters"
        to={PATHS.RESOURCES_ELASTICACHE}
        end
      />
      <SidebarLink
        aria-label="Go to Redshift Clusters"
        count={truncateMaxCount(redshiftClusters?.totalCount)}
        loading={resourcesLoading}
        size="small"
        title="Redshift Clusters"
        to={PATHS.RESOURCES_REDSHIFT}
        end
      />
    </>
  );
};
