import { useAccessRequired } from "@m/api/components";

export const useUserManagementAccess = () => {
  const allowResendInvite = useAccessRequired({
    mutation: "resendInvitation",
  });
  const allowUpdateRole = useAccessRequired({
    mutation: "updateCompanyTeamMemberRole",
  });
  const allowRemoveUser = useAccessRequired({
    mutation: "removeUser",
  });

  return { allowResendInvite, allowUpdateRole, allowRemoveUser };
};
