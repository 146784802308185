import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { AccessRequired } from "@m/api/components";
import { CompanyBookmark } from "@m/api/public/types";
import { Button, Link, Spinner } from "@m/ui";
import { Card } from "@m/ui/components/Card";

import { MUTATIONS, PATHS } from "@mc/constants";
import { useCompanyBookmarks } from "@mc/features/BookmarkSettings/api";
import { BookmarkFavicon } from "@mc/features/BookmarkSettings/components";
import { insertUrlScheme } from "@mc/features/BookmarkSettings/utils";

export const BookmarksWidget = () => {
  const {
    data: { bookmarks },
    loading,
  } = useCompanyBookmarks();

  const hasBookmarks = bookmarks.length > 0;

  return (
    <div>
      <div className="flex items-center justify-between p-1">
        <p className="font-semibold">Bookmarks</p>
        <AccessRequired mutation={MUTATIONS.SET_COMPANY_BOOKMARKS}>
          <Link
            to={PATHS.SETTINGS_BOOKMARKS}
            fontSize="sm"
            leftIcon={PencilIcon}
          >
            Edit
          </Link>
        </AccessRequired>
      </div>
      <Card
        data-testid="bookmarks-widget"
        className={clsx({ "bg-zinc-50": !hasBookmarks })}
      >
        {loading && (
          <div className="flex justify-center">
            <Spinner className="my-4" />
          </div>
        )}
        {!loading && !hasBookmarks && <EmptyState />}
        {!loading && hasBookmarks && <BookmarksList bookmarks={bookmarks} />}
      </Card>
    </div>
  );
};

const BookmarksList = ({ bookmarks }: { bookmarks: CompanyBookmark[] }) => (
  <div className="flex flex-col gap-2 px-3 py-2">
    {bookmarks.map((bookmark) => (
      <Link
        key={bookmark.id}
        href={insertUrlScheme(bookmark.url)}
        target="_blank"
        className="hover:cursor-pointer hover:underline"
      >
        <div className="flex items-center gap-1.5">
          <span className="flex basis-1/2 items-center gap-1.5">
            <BookmarkFavicon url={bookmark.url} />
            {bookmark.linkDisplayName}
          </span>
          <div className="basis-1/2 font-normal text-subdued">
            {bookmark.linkShortDescription}
          </div>
        </div>
      </Link>
    ))}
  </div>
);

const EmptyState = () => (
  <div className="flex flex-col gap-2 p-4">
    <div className="text-center">There are no bookmarks recorded.</div>
    <Button
      className="m-auto w-fit"
      leftIcon={PlusIcon}
      to={PATHS.SETTINGS_BOOKMARKS}
    >
      Add bookmark
    </Button>
  </div>
);
