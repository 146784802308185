import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";

export const HAS_BUSINESS_SUPPORT = gql(/* GraphQL */ `
  query HasBusinessSupport {
    userProfile {
      currentCompany {
        hasBusinessSupport
      }
    }
  }
`);

export const useHasBusinessSupport = () => {
  const { data, ...result } = useQuery(HAS_BUSINESS_SUPPORT);

  const hasBusinessSupport =
    data?.userProfile?.currentCompany?.hasBusinessSupport;

  return { hasBusinessSupport, ...result };
};
