import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAccessRequired } from "@m/api/components";
import { SpinnerScreen } from "@m/ui";

import { SKUS } from "@mc/constants";
import { usePendingOnboarding } from "@mc/features/Onboarding/hooks";

import { useHasBusinessSupport, usePollCloudScore } from "../api";
import { CLOUD_SCORE_POLL_INTERVAL } from "../constants";

interface CloudScoreProviderProps extends PropsWithChildren {
  /** Interval used to poll for cloud score availability */
  pollInterval?: number;
}

export const CloudScoreProvider = ({
  children,
  pollInterval = CLOUD_SCORE_POLL_INTERVAL,
}: CloudScoreProviderProps) => {
  const isCloudScoreUser = useAccessRequired({
    entitlement: SKUS.CLOUD_SCORE,
  });
  const pendingOnboarding = usePendingOnboarding(SKUS.CLOUD_SCORE);

  const shouldPoll = isCloudScoreUser === true && pendingOnboarding === null;

  const [isPreparingCloudScore, setIsPreparingCloudScore] =
    useState(shouldPoll);

  const { hasBusinessSupport } = useHasBusinessSupport();

  const {
    data: { hasCloudScore },
    loading,
    stopPolling,
  } = usePollCloudScore(pollInterval, shouldPoll);

  useEffect(
    function handleStopPolling() {
      if (shouldPoll && (hasCloudScore || hasBusinessSupport === false)) {
        stopPolling();
        setIsPreparingCloudScore(false);
      }
    },
    [shouldPoll, hasCloudScore, hasBusinessSupport, stopPolling]
  );

  return (
    <CloudScoreContext.Provider
      value={{ isPreparingCloudScore, hasBusinessSupport }}
    >
      {loading ? <SpinnerScreen /> : children}
    </CloudScoreContext.Provider>
  );
};

type CloudScoreContextType = {
  isPreparingCloudScore: boolean | undefined;
  hasBusinessSupport: boolean | undefined;
};

const CloudScoreContext = createContext<CloudScoreContextType>({
  isPreparingCloudScore: undefined,
  hasBusinessSupport: undefined,
});

export const useCloudScoreContext = () => useContext(CloudScoreContext);
