import { RadioGroup } from "@headlessui/react";

import { AccessRequired } from "@m/api/components";
import { Field } from "@m/ui";

import { FEATURE_FLAGS } from "@mc/constants";

import { CaseType } from "../types";

import { RadioGroupOption } from "./RadioGroupOption";

interface CaseTypeRadioGroupProps {
  caseType: CaseType;
  onChange: (caseType: CaseType) => void;
}

export const CaseTypeRadioGroup = ({
  caseType,
  onChange,
}: CaseTypeRadioGroupProps) => {
  return (
    <Field label="Case Type" row="2/3" rowAlign="top">
      <RadioGroup
        value={caseType}
        onChange={onChange}
        className="grid grid-cols-2 gap-1"
      >
        <AccessRequired
          feature={FEATURE_FLAGS.SUPPORT_CASES_ALLOW_AWS_ACCOUNT_SUPPORT}
        >
          <RadioGroupOption value={CaseType.Account} label="Account Support" />
        </AccessRequired>
        <AccessRequired
          feature={FEATURE_FLAGS.SUPPORT_CASES_ALLOW_BILLING_SUPPORT}
        >
          <RadioGroupOption value={CaseType.Billing} label="Billing Support" />
        </AccessRequired>
        <AccessRequired
          feature={FEATURE_FLAGS.SUPPORT_CASES_ALLOW_OPERATIONAL_SUPPORT}
        >
          <RadioGroupOption
            value={CaseType.Operations}
            label="Operations Support"
          />
        </AccessRequired>
        <AccessRequired
          feature={FEATURE_FLAGS.SUPPORT_CASES_ALLOW_TECHNICAL_SUPPORT}
        >
          <RadioGroupOption
            value={CaseType.Support}
            label="Technical Support"
          />
        </AccessRequired>
        <AccessRequired
          feature={FEATURE_FLAGS.SUPPORT_CASES_ALLOW_CLOUD_GATEWAY_SUPPORT}
        >
          <RadioGroupOption value={CaseType.AwsGuidance} label="AWS Guidance" />
        </AccessRequired>
      </RadioGroup>
    </Field>
  );
};
