import { formatFullDate, getMilliseconds } from "@m/utils";

import {
  ACTIVITY_FEED_GROUPS,
  ACTIVITY_FEED_INITIAL_CHANGES,
} from "@mc/features/ActivityFeed/constants";

const DATE_FORMAT = "yyyy-MM-dd";

export const getScoreChanges = (
  scorecards,
  activityFeedGroups = ACTIVITY_FEED_GROUPS
) => {
  const changes = { ...ACTIVITY_FEED_INITIAL_CHANGES };
  for (const { id, startTime, endTime } of activityFeedGroups) {
    const startDate = formatFullDate(startTime, DATE_FORMAT);
    const endDate = formatFullDate(endTime, DATE_FORMAT);

    const scorecardsWithinRange = scorecards.filter((scorecard) => {
      const created = getMilliseconds(scorecard.created);
      return (
        created >= getMilliseconds(startDate) &&
        created <= getMilliseconds(endDate)
      );
    });

    const firstScorecardAvailable =
      scorecardsWithinRange[scorecardsWithinRange.length - 1];
    const lastScorecardAvailable = scorecardsWithinRange[0];

    const startScorecard =
      scorecardsWithinRange.find(
        (scorecard) => scorecard.created === startDate
      ) || firstScorecardAvailable;
    const endScorecard =
      scorecardsWithinRange.find(
        (scorecard) => scorecard.created === endDate
      ) || lastScorecardAvailable;

    if (startScorecard && endScorecard)
      changes[id] = parseFloat(
        (endScorecard.score - startScorecard.score).toFixed(1)
      );
  }

  return changes;
};
