import { AccessRequired } from "@m/api/components";
import { Link, Spinner } from "@m/ui";

import { MUTATIONS } from "@mc/constants";

import { EngagementSectionHeader } from "../components/EngagementDetailsLists";

export const EngagementsPoolHoursWidget = ({
  poolHours,
  onEditClick,
  isLoading,
}: {
  poolHours: number;
  onEditClick: () => void;
  isLoading: boolean;
}) => {
  return (
    <div data-testid="pool-hours-widget" className="w-4/12">
      <div className="px-2">
        <EngagementSectionHeader title="Pool Hours" />
      </div>

      <div className="my-1 flex flex-col gap-0.5 rounded-md bg-gray-50 p-2">
        <div className="flex w-full justify-between">
          <div className="w-fit text-xs font-semibold tracking-wide text-subdued">
            Monthly Hours
          </div>
          <AccessRequired mutation={MUTATIONS.UPDATE_ENGAGEMENT}>
            <Link
              as="button"
              fontSize="sm"
              className="w-fit"
              onClick={onEditClick}
            >
              Edit
            </Link>
          </AccessRequired>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="font-semibold text-default">{poolHours || 0}</div>
        )}
      </div>
    </div>
  );
};
