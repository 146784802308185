import { CheckCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { Link } from "@m/ui";

import { useRecursiveTimeout } from "@mc/hooks";

import styles from "./PreparingAccount.module.css";

interface PreparingAccountProps {
  /** Loading state of the API call to create the account */
  loading: boolean;

  /** URL to redirect to after the account is ready */
  redirectUrl?: string;
}

export const PreparingAccount = ({
  loading,
  redirectUrl,
}: PreparingAccountProps) => {
  const [remaining, setRemaining] = useState<number>(3);

  useRecursiveTimeout(() => {
    if (loading || remaining === 0) return;

    if (remaining > 1) {
      setRemaining((prev) => (prev -= 1));
    } else {
      setRemaining(0);
    }
  }, 1000);

  useEffect(() => {
    if (remaining === 0) window.open(redirectUrl);
  }, [remaining, redirectUrl]);

  return (
    <div role="status" className="flex flex-col items-center gap-5">
      <div className="relative w-fit">
        <div
          className={clsx(
            "h-[240px] w-[240px] rounded-full border-8 border-solid",
            {
              [styles.animateSpin]: loading,
              "border-green-100 border-t-status-good": loading,
              "border-status-good": !loading,
            }
          )}
        />

        {loading ? <LoadingBars /> : <ReadyCheckmark />}
      </div>

      <div className="text-4xl font-bold text-default">
        {loading ? "Preparing your account" : "Your account is ready!"}
      </div>

      {!loading && remaining > 0 && (
        <div className="text-xl font-normal text-subdued">
          Set your password in {remaining}...
        </div>
      )}

      {!loading && (
        <div>
          You will be redirected to set your password. If you are not
          redirected, <Link href={redirectUrl}>click here.</Link>
        </div>
      )}
    </div>
  );
};

const LoadingBars = () => {
  const barStyles = "h-full w-2 rounded-md bg-white";

  return (
    <div
      className="absolute left-5 top-5 h-[144px] w-[144px] rounded-full border-2 border-white bg-status-good p-4"
      data-testid="loading-bars"
    >
      <div className="flex h-full items-end justify-center gap-1">
        <div className={clsx(barStyles, styles.animateLeft)} />
        <div className={clsx(barStyles, styles.animateMiddle)} />
        <div className={clsx(barStyles, styles.animateRight)} />
      </div>
    </div>
  );
};

const ReadyCheckmark = () => {
  return (
    <CheckCircleIcon
      className="absolute left-4 top-4 h-[176px] w-[176px] fill-status-good"
      data-testid="ready-checkmark"
    />
  );
};
