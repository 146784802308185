import { Outlet } from "react-router-dom";

import {
  CloudScoreIneligiblePage,
  PreparingCloudScorePage,
} from "@mc/features/CloudScore";
import { useCloudScoreContext } from "@mc/features/CloudScore/providers";

/**
 * Renders child routes, if the user's Cloud Score is available,
 * otherwise renders Preparing Cloud Score page.
 */
export const CloudScoreOutlet = () => {
  const { isPreparingCloudScore, hasBusinessSupport } = useCloudScoreContext();

  if (hasBusinessSupport === false) return <CloudScoreIneligiblePage />;
  if (isPreparingCloudScore) return <PreparingCloudScorePage />;

  return <Outlet />;
};
