import { PlusIcon } from "@heroicons/react/24/outline";

import { AccessRequired } from "@m/api/components";
import { Link, Spinner } from "@m/ui";
import { Card } from "@m/ui/components/Card";

import { PATHS } from "@mc/constants";
import {
  CASE_LIST_STATUS_FILTERS,
  CASE_LIST_TYPE_FILTERS,
} from "@mc/features/SupportCases/constants";

import { useCaseMetrics } from "../api";

export const CaseMetricsWidget = () => {
  const {
    data: { activeCases, needResponse, alerts },
    loading,
  } = useCaseMetrics();

  return (
    <div>
      <div className="flex items-center justify-between p-1">
        <p className="font-semibold">Cases</p>
        <AccessRequired mutation="createCase">
          <Link
            to={PATHS.SUPPORT_CASES_CREATE}
            fontSize="sm"
            leftIcon={PlusIcon}
          >
            New Case
          </Link>
        </AccessRequired>
      </div>
      <Card data-testid="case-metrics-widget">
        {loading && (
          <div className="flex justify-center">
            <Spinner className="my-4" />
          </div>
        )}
        {!loading && (
          <div className="my-2 grid auto-cols-min grid-cols-3 lg:grid-cols-1 xl:grid-cols-3">
            <CaseMetricCount
              label="Open"
              to={`/support/cases?status=${CASE_LIST_STATUS_FILTERS.open.value}`}
              count={activeCases}
            />
            <CaseMetricCount
              label="Need Response"
              to={`/support/cases?status=${CASE_LIST_STATUS_FILTERS.open.value}`}
              count={needResponse}
            />
            <CaseMetricCount
              label="Alerts"
              to={`/support/cases?type=${CASE_LIST_TYPE_FILTERS.alerts.value}`}
              count={alerts}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

const CaseMetricCount = ({
  label,
  to,
  count,
}: {
  label: string;
  to: string;
  count: number;
}) => {
  return (
    <div className="text-center font-semibold">
      <Link
        to={to}
        className="cursor-pointer rounded-lg px-0.5 py-1 text-subdued hover:bg-slate-50"
        aria-label={label}
      >
        <div className="mb-0.5 whitespace-nowrap text-sm">{label}</div>
        <p className="text-left text-lg text-action lg:text-center xl:text-left">
          {count === null || count === undefined ? "-" : count}
        </p>
      </Link>
    </div>
  );
};
