import {
  ClockIcon,
  FingerPrintIcon,
  InformationCircleIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";

import { useAuth } from "@m/login";
import { Banner, Button, Link, Toggle, Tooltip } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { SSO_SESSION_URL } from "@mc/constants";

import { useEnforceMfa } from "../api";

export const SecuritySettingsPage = () => {
  const [enforceMfa, { loading }] = useEnforceMfa();
  const { user: currentUser, updateUserSession } = useAuth();
  const isSSO = currentUser?.company?.sso === true;
  const isMFA = currentUser?.company?.mfa === true;

  const [mfaEnforced, setMfaEnforced] = useState(isMFA);

  const saveMfa = (value) => {
    setMfaEnforced(value);
    updateUserSession({
      ...currentUser,
      company: {
        ...currentUser.company,
        mfa: value,
      },
    });
    enforceMfa({ required: value });
  };

  return (
    <Page data-testid="security-settings-page">
      <PageHeading
        heading="Security"
        description="Configure security settings for all users in your company"
      />

      <div className="border-zinc-10 relative mb-2 flex flex-col gap-y-5 rounded-md border-[1px] border-solid p-3">
        <Section
          title="Multi-Factor Authentication"
          tooltip="Only admin users can change this setting."
        >
          <div>
            <div className="text-xs">
              Add an extra layer of security for users by requiring a two-step
              verification for all logins.
            </div>
          </div>
          <div>
            {isSSO ? (
              <>
                <Banner
                  className="mb-1 mt-2 text-xs text-action"
                  label="Single Sign-On (SAML) is configured for all users in your company.  Please use your SAML-based single sign-on provider to enforce MFA."
                />
                <div className="static right-4 top-4 mt-4 lg:absolute lg:mt-0">
                  <Toggle disabled={true} checked={false} onChange={() => {}} />
                  <span className="px-1 align-top font-bold">Off</span>
                </div>
              </>
            ) : (
              <>
                <div className="pt-2 text-xs">Available options include:</div>
                <div className="text-xs font-medium text-subdued">
                  <ul className="list-inside pt-2">
                    <li className="pt-1.5">
                      <KeyIcon className="mr-1 inline w-3" />
                      WebAuthn with FIDO Security Keys (such as{" "}
                      <Link
                        href="https://www.yubico.com/"
                        rel="noreferrer"
                        target="_blank"
                        className="underline"
                      >
                        Yubikey
                      </Link>{" "}
                      and{" "}
                      <Link
                        href="https://cloud.google.com/security/products/titan-security-key?hl=en"
                        rel="noreferrer"
                        target="_blank"
                        className="underline"
                      >
                        Google Titan
                      </Link>
                      )
                    </li>
                    <li className="pt-1.5">
                      <FingerPrintIcon className="mr-1 inline w-3" />
                      <Link
                        href="https://webauthn.me/browser-support"
                        rel="noreferrer"
                        target="_blank"
                        className="underline"
                      >
                        WebAuthn with FIDO Device Biometrics
                      </Link>
                    </li>
                    <li className="pt-1.5">
                      <ClockIcon className="mr-1 inline w-3" />
                      One-time Password (such as Google Authenticator and{" "}
                      <Link
                        href="https://1password.com/"
                        rel="noreferrer"
                        target="_blank"
                        className="underline"
                      >
                        1Password
                      </Link>
                      )
                    </li>
                  </ul>
                </div>
                <div className="static right-4 top-4 mt-4 lg:absolute lg:mt-0">
                  <Toggle
                    disabled={loading}
                    checked={mfaEnforced}
                    onChange={saveMfa}
                  />
                  <span className="px-1 align-top font-bold">
                    {mfaEnforced ? "On" : "Off"}
                  </span>
                </div>
              </>
            )}
          </div>
        </Section>
      </div>
      {isSSO ? null : (
        <div className="border-zinc-10 relative flex flex-col gap-y-5 rounded-md border-[1px] border-solid p-3">
          <Section title="SAML for Single Sign-On">
            <div className="text-xs">
              Already have an IdP you'd prefer to use? Mission Control supports
              SAML for Single Sign-On, enabling secure and seamless access.{" "}
            </div>
            <div className="mt-1">
              <Link href={SSO_SESSION_URL}>
                <Button kind="primary">Request SSO Enablement</Button>
              </Link>
              <Link
                href="https://resources.missioncloud.com/en/articles/10244522-configuring-saml-based-sso"
                rel="noreferrer"
                target="_blank"
                className="mx-1"
              >
                <Button kind="secondary">Learn More</Button>
              </Link>
            </div>
            <div className="static right-4 top-4 mt-4 lg:absolute lg:mt-0">
              <Toggle disabled={true} checked={false} onChange={() => {}} />
              <span className="px-1 align-top font-bold opacity-30">Off</span>
            </div>
          </Section>
        </div>
      )}
    </Page>
  );
};

const Section = ({ children, title, tooltip = null }) => (
  <div className="flex flex-col gap-y-1">
    <div className="text-sm font-semibold text-action">
      {title}{" "}
      {tooltip ? (
        <Tooltip content={tooltip}>
          <InformationCircleIcon className="inline h-2.5 w-2.5 hover:cursor-help" />
        </Tooltip>
      ) : null}
    </div>
    {children}
  </div>
);
