import { RadioGroup } from "@headlessui/react";

import { AccessRequired } from "@m/api/components";
import { Field } from "@m/ui";

import { FEATURE_FLAGS } from "@mc/constants";
import { RadioGroupOption } from "@mc/features/SupportCases/components";

import { RequestType } from "../types";

interface RequestTypeRadioGroupProps {
  requestType: RequestType;
  onChange: (requestType: RequestType) => void;
}

export const RequestTypeRadioGroup = ({
  requestType,
  onChange,
}: RequestTypeRadioGroupProps) => {
  return (
    <Field label="Request Type" row="2/3" rowAlign="top">
      <RadioGroup
        value={requestType}
        onChange={onChange}
        className="grid grid-cols-2 gap-1"
      >
        <AccessRequired
          feature={FEATURE_FLAGS.ENGINEER_ASSIST_ALLOW_DEVOPS_DESK}
        >
          <RadioGroupOption value={RequestType.DevOpsDesk} label="DevOps" />
        </AccessRequired>
        <AccessRequired feature={FEATURE_FLAGS.ENGINEER_ASSIST_ALLOW_AI_DESK}>
          <RadioGroupOption value={RequestType.AIDesk} label="AI" />
        </AccessRequired>
      </RadioGroup>
    </Field>
  );
};
