import { Outlet, RouteObject } from "react-router-dom";

import { AccessRequired } from "@m/api/components";

import { StaffOnly } from "@mc/components/StaffOnly";
import {
  FEATURE_FLAGS,
  PATHS,
  SUPPORT_CASES_FEATURE_FLAGS,
} from "@mc/constants";
import { MissionAdminNavigation } from "@mc/features/MissionAdmin";
import { RioReportingPage } from "@mc/features/RioReporting";
import { SettingsNavigation } from "@mc/features/Settings";
import { AuthenticatedSignupPage } from "@mc/features/Signup";
import { NavigationLayout } from "@mc/layouts";
import { AccessDenied, NotFound } from "@mc/pages";
import { Navigate } from "@mc/router";

import { cloudScoreRoutes } from "./cloudScoreRoutes";
import { dashboardRoutes } from "./dashboardRoutes";
import { engagementsRoutes } from "./engagementsRoutes";
import { CloudScoreOutlet } from "./outlets";
import { resourcesRoutes } from "./resourcesRoutes";
import { scorecardRoutes } from "./scorecardRoutes";
import { settingsRoutes } from "./settingsRoutes";
import { staffRoutes } from "./staffRoutes";
import { supportRoutes } from "./supportRoutes";

export const defaultRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to={PATHS.DASHBOARD} />,
  },
  {
    path: "cloud-score",
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.CLOUD_SCORE_ALLOW_ACCESS}
        fallback={<NotFound />}
      >
        <CloudScoreOutlet />
      </AccessRequired>
    ),
    children: cloudScoreRoutes,
  },
  {
    path: "dashboard",
    children: dashboardRoutes,
  },
  {
    path: "engagements",
    children: engagementsRoutes,
  },
  {
    path: "resources",
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.AWS_RESOURCES_ALLOW_ACCESS}
        fallback={<NotFound />}
      >
        <Outlet />
      </AccessRequired>
    ),
    children: resourcesRoutes,
  },
  {
    path: "rio",
    element: (
      <AccessRequired
        feature={FEATURE_FLAGS.RIO_REPORTING_ALLOW_ACCESS}
        fallback={<NotFound />}
      >
        <RioReportingPage />
      </AccessRequired>
    ),
  },
  { path: "scorecard", children: scorecardRoutes },
  {
    path: "settings",
    element: (
      <NavigationLayout navigation={<SettingsNavigation />} title="Settings">
        <Outlet />
      </NavigationLayout>
    ),
    children: settingsRoutes,
  },
  {
    path: "signup/:token",
    element: <AuthenticatedSignupPage />,
  },
  {
    path: "staff",
    element: (
      <StaffOnly fallback={<NotFound />}>
        <AccessRequired
          feature={FEATURE_FLAGS.MISSION_ADMIN_ALLOW_ACCESS}
          fallback={<AccessDenied />}
        >
          <NavigationLayout
            navigation={<MissionAdminNavigation />}
            title="Mission Admin"
          >
            <Outlet />
          </NavigationLayout>
        </AccessRequired>
      </StaffOnly>
    ),
    children: staffRoutes,
  },
  {
    path: "support",
    element: (
      <AccessRequired
        feature={SUPPORT_CASES_FEATURE_FLAGS}
        matchFeatures="any"
        fallback={<NotFound />}
      >
        <Outlet />
      </AccessRequired>
    ),
    children: supportRoutes,
  },
  { path: "*", element: <NotFound /> },
];
