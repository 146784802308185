import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { ELASTICACHE_LABEL, GET_SEARCH_TERMS_ERROR } from "../constants";

export const GET_ELASTICACHE_CLUSTER_SEARCH_TERMS = gql(/* GraphQL */ `
  query ElasticacheClusterSearchTerms(
    $searchTerm: String
    $searchFields: [String]
  ) {
    elasticacheClusterSearchTerms(
      searchTerm: $searchTerm
      searchFields: $searchFields
    ) {
      searchTerms
    }
  }
`);

export const useElasticacheClusterSearchTerms = () => {
  const onError = () => toast.error(GET_SEARCH_TERMS_ERROR(ELASTICACHE_LABEL));

  const { data, loading, error, refetch } = useQuery(
    GET_ELASTICACHE_CLUSTER_SEARCH_TERMS,
    { onError }
  );

  const searchTerms = data?.elasticacheClusterSearchTerms?.searchTerms || [];

  return {
    data: { searchTerms },
    error,
    loading,
    refetch,
  };
};
