import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { RedshiftClusterFilter } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { GET_RESOURCES_FAILURE, REDSHIFT_LABEL } from "../constants";

export const GET_REDSHIFT_CLUSTERS = gql(/* GraphQL */ `
  query RedshiftClusters(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchFields: [String]
    $searchTerm: String
    $sort: [RedshiftClusterSortEnum]
    $where: RedshiftClusterFilter
  ) {
    redshiftClusters(
      after: $after
      before: $before
      first: $first
      last: $last
      searchFields: $searchFields
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          account
          accountId
          accountName
          availabilityZone
          clusterName
          createTime
          id
          isMissionManaged
          nodeType
          region
          status
          tagTotal
          tags
        }
      }
    }
  }
`);

export const useRedshiftClusters = (query) => {
  const where: RedshiftClusterFilter = {};

  if (query.missionManaged === "Managed") where.isMissionManaged = true;
  if (query.missionManaged === "Unmanaged") where.isMissionManaged = false;
  if (query.accounts) where.accountIn = query.accounts;
  if (query.statuses) where.statusIn = query.statuses;
  if (query.nodeTypes) where.nodeTypeIn = query.nodeTypes;

  const onError = () => toast.error(GET_RESOURCES_FAILURE(REDSHIFT_LABEL));

  const { data, loading, error, fetchMore, refetch } = useQuery(
    GET_REDSHIFT_CLUSTERS,
    {
      onError,
      variables: {
        sort: query.sort,
        ...INITIAL_PAGINATION,
        searchTerm: query.search,
        where,
      },
    }
  );

  const { edges, pageInfo, totalCount } = data?.redshiftClusters || {};
  const clusters = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { clusters },
    error,
    loading,
    pagination,
    refetch,
  };
};
