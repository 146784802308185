import {
  oneDayAgo,
  oneHundredTwentyDaysAgo,
  oneWeekAgo,
  rightNow,
  startOfCurrentMonth,
  startOfLastMonth,
} from "../utils";

export const ACTIVITY_FEED_GROUPS = [
  {
    id: "today",
    title: "Today",
    startTime: oneDayAgo(),
    endTime: rightNow(),
  },
  {
    id: "thisWeek",
    title: "This Week",
    startTime: oneWeekAgo(),
    endTime: oneDayAgo(),
  },
  {
    id: "thisMonth",
    title: "This Month",
    startTime: startOfCurrentMonth(),
    endTime: oneWeekAgo(),
  },
  {
    id: "lastMonth",
    title: "Last Month",
    startTime: startOfLastMonth(),
    endTime: startOfCurrentMonth(),
  },
  {
    id: "older",
    title: "Older",
    startTime: oneHundredTwentyDaysAgo(),
    endTime: startOfLastMonth(),
  },
];
