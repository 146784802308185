import { createContext, useContext, useState } from "react";
import { Helmet } from "react-helmet-async";

import { useAuth } from "@m/login";

export const IntercomContext = createContext(null);
export const useIntercom = () => useContext(IntercomContext);

export const IntercomProvider = ({ children }) => {
  const [api, setApi] = useState(null);
  const { user } = useAuth();

  // End early if there isn't an API key loaded
  if (!process.env.REACT_APP_SERVICES_INTERCOM_APP_ID) return children;

  const app_id = process.env.REACT_APP_SERVICES_INTERCOM_APP_ID;
  const src = `https://widget.intercom.io/widget/${app_id}`;
  const uid = "intercom-script-src";
  window.intercomSettings = {
    app_id: app_id,
    hide_default_launcher: true,
    api_base: "https://api-iam.intercom.io",
    user_id: user.id,
    name: user.name,
    email: user.email,
    user_hash: user.intercomHash,
    company: {
      company_id: user.company.id,
      name: user.company.name,
    },
  };

  const onLoad = ({ scriptTags }) => {
    // react-helmel-async doesn't natively support script onload
    // https://github.com/nfl/react-helmet/issues/146#issuecomment-2143617246
    const tag = scriptTags?.find((tag) => tag.id == src);
    if (tag !== undefined) {
      tag.onload = () => {
        window.Intercom("boot", window.intercomSettings);
        setApi(window.Intercom);
      };
    }
  };

  return (
    <IntercomContext.Provider value={api}>
      <Helmet onChangeClientState={(newState, addedTags) => onLoad(addedTags)}>
        <script defer src={src} id={uid} />
      </Helmet>
      {children}
    </IntercomContext.Provider>
  );
};
