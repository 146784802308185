import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { EcsClusterFilter } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { ECS_LABEL, GET_RESOURCES_FAILURE } from "../constants";

export const GET_ECS_CLUSTERS = gql(/* GraphQL */ `
  query EcsClusters(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: EcsClusterFilter
    $searchTerm: String
    $searchFields: [String]
    $sort: [EcsClusterSortEnum]
  ) {
    ecsClusters(
      first: $first
      after: $after
      last: $last
      before: $before
      sort: $sort
      where: $where
      searchTerm: $searchTerm
      searchFields: $searchFields
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          accountId
          accountName
          clusterArn
          clusterName
          id
          isMissionManaged
          region
          status
          tagTotal
          tags
        }
      }
    }
  }
`);

export const useEcsClusters = (query) => {
  const where: EcsClusterFilter = {};

  if (query.missionManaged === "Managed") where.isMissionManaged = true;
  if (query.missionManaged === "Unmanaged") where.isMissionManaged = false;
  if (query.accounts) where.accountIn = query.accounts;

  const onError = () => toast.error(GET_RESOURCES_FAILURE(ECS_LABEL));

  const { data, loading, error, fetchMore, refetch } = useQuery(
    GET_ECS_CLUSTERS,
    {
      onError,
      variables: {
        sort: query.sort,
        ...INITIAL_PAGINATION,
        searchTerm: query.search,
        where,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const { edges, pageInfo, totalCount } = data?.ecsClusters || {};
  const clusters = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { clusters },
    error,
    loading,
    pagination,
    refetch,
  };
};
