import { RouteObject } from "react-router-dom";

import { AccessRequired } from "@m/api/components";

import { FEATURE_FLAGS, MUTATIONS, PATHS } from "@mc/constants";
import {
  AccountsPage,
  AddAccountPage,
} from "@mc/features/AccountSettings/pages";
import { NotFound } from "@mc/pages/404";
import { Navigate } from "@mc/router";

export const accountsRoutes: RouteObject[] = [
  {
    index: true,
    element: <AccountsPage />,
  },
  {
    path: "add",
    element: (
      <AccessRequired
        fallback={<Navigate replace to={PATHS.SETTINGS_ACCOUNTS} />}
        feature={FEATURE_FLAGS.ACCOUNTS_ALLOW_ADD_AWS_ACCOUNT}
        mutation={MUTATIONS.CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST}
      >
        <AddAccountPage />,
      </AccessRequired>
    ),
  },
  {
    path: "connect/:id",
    element: (
      <AccessRequired
        fallback={<Navigate replace to={PATHS.SETTINGS_ACCOUNTS} />}
        feature={FEATURE_FLAGS.ACCOUNTS_ALLOW_CONNECT_AWS_ACCOUNT}
        mutation={MUTATIONS.CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST}
      >
        <AddAccountPage />,
      </AccessRequired>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
