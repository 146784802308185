import { ArrowsUpDownIcon } from "@heroicons/react/20/solid";
import { MutableRefObject, useMemo } from "react";

import { AccessRequired } from "@m/api/components";
import { Activity, Conversation } from "@m/api/public/types";
import { Button } from "@m/ui";
import { dt, getMilliseconds } from "@m/utils";

import { FEATURE_FLAGS } from "@mc/constants";
import { useUserPreference } from "@mc/hooks";

import { AttachmentActivity } from "./components/AttachmentActivity";
import { CommentActivity } from "./components/CommentActivity";
import { IntercomActivity } from "./components/IntercomActivity";
import { ReplyTimelineForm } from "./components/ReplyTimelineForm";
import { ResolutionNotesActivity } from "./components/ResolutionNotesActivity";
import { ACTIVITY_TYPES } from "./constants";

export interface ReplyTimelineProps {
  activities: Array<Activity | Conversation>;
  allowReplies: boolean;
  detail: any;
  textBoxRef: MutableRefObject<any>;
  setActivities: (value: { node: Activity | Conversation }[]) => void;
  refetch: () => void;
}

export const REPLY_SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
};

export function ReplyTimeline({
  activities,
  allowReplies,
  detail,
  textBoxRef,
  setActivities,
  refetch,
}: ReplyTimelineProps) {
  const { closeNotes } = detail || {};

  const [replySortOrder, setReplySortOrder] = useUserPreference<string>(
    "replySortOrder",
    REPLY_SORT_ORDER.ASC
  );

  const sortedActivities = [...activities].sort((a, b) => {
    return replySortOrder === REPLY_SORT_ORDER.ASC
      ? getMilliseconds(dt.fromISO(a.createdAt)) -
          getMilliseconds(dt.fromISO(b.createdAt))
      : getMilliseconds(dt.fromISO(b.createdAt)) -
          getMilliseconds(dt.fromISO(a.createdAt));
  });

  const activityList = useMemo(() => {
    return sortedActivities
      .filter(
        (activity) =>
          "chatParticipants" in activity ||
          ("type" in activity &&
            activity?.type !== ACTIVITY_TYPES.INTERNAL_NOTE &&
            activity?.type !== ACTIVITY_TYPES.AWS_RESPONSE)
      )
      .map((activity) => {
        const conversationId =
          "chatParticipants" in activity ? activity.conversationId : null;
        const sysId = "type" in activity ? activity.sysId : null;

        const displayIntercomActivity =
          "chatParticipants" in activity &&
          activity.chatParticipants &&
          activity.closedBy;

        if (displayIntercomActivity)
          return (
            <AccessRequired
              key={`activity-${conversationId}`}
              feature={FEATURE_FLAGS.SUPPORT_CASES_ALLOW_LIVE_CHAT}
            >
              <IntercomActivity activity={activity} />
            </AccessRequired>
          );

        const displayAttachment =
          "type" in activity &&
          activity?.type === ACTIVITY_TYPES.ATTACHMENT &&
          // If an attachment is already associated with a comment activity, we'll display the files along with the comment in CommentActivity instead
          !activity?.attachmentHasComment;
        if (displayAttachment)
          return (
            <AttachmentActivity key={`activity-${sysId}`} activity={activity} />
          );

        return (
          <CommentActivity
            key={`activity-${sysId}`}
            comment={activity as Activity}
          />
        );
      });
  }, [sortedActivities]);

  const handleReplyOrderChange = () => {
    const updatedSortOrder =
      replySortOrder === REPLY_SORT_ORDER.ASC
        ? REPLY_SORT_ORDER.DESC
        : REPLY_SORT_ORDER.ASC;

    setReplySortOrder(updatedSortOrder);
  };

  const orderedTimeline =
    replySortOrder === REPLY_SORT_ORDER.ASC ? (
      <>
        <div data-testid="activities-list" className="mb-4 space-y-4">
          {activityList}
        </div>
        {closeNotes && <ResolutionNotesActivity notes={closeNotes} />}

        {allowReplies && (
          <ReplyTimelineForm
            detail={detail}
            textBoxRef={textBoxRef}
            setActivities={setActivities}
            refetch={refetch}
          />
        )}
      </>
    ) : (
      <>
        {allowReplies && (
          <ReplyTimelineForm
            detail={detail}
            textBoxRef={textBoxRef}
            setActivities={setActivities}
            refetch={refetch}
          />
        )}

        {closeNotes && <ResolutionNotesActivity notes={closeNotes} />}
        <div data-testid="activities-list" className="space-y-4">
          {activityList}
        </div>
      </>
    );
  const timelineOrderButtonText =
    replySortOrder === REPLY_SORT_ORDER.ASC
      ? "Oldest to Newest"
      : "Newest to Oldest";

  return (
    <div className="relative">
      <div className="mb-2 flex w-full items-stretch justify-between">
        <h3 className="self-center text-lg">Replies</h3>
        <Button
          className="self-center pr-0"
          onClick={handleReplyOrderChange}
          kind="primary"
          fill="none"
          leftIcon={ArrowsUpDownIcon}
          iconClassName={"text-default"}
        >
          {timelineOrderButtonText}
        </Button>
      </div>
      {orderedTimeline}
    </div>
  );
}
