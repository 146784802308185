import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

import { AccessRequired } from "@m/api/components";
import { Dropdown, DropdownItem, Suspensed } from "@m/ui";

import { ROLES } from "@mc/constants";

interface EngagementsPageTitleActionsProps {
  loading?: boolean;
  onEditClick: () => void;
  onShowAllItems: () => void;
  onStatusUpdateClick?: () => void;
  shouldShowAllItems?: boolean;
}

export const EngagementsPageTitleActions = ({
  loading = false,
  onEditClick,
  onShowAllItems,
  onStatusUpdateClick,
  shouldShowAllItems = false,
}: EngagementsPageTitleActionsProps) => {
  return (
    <Suspensed loading={loading} width="95px" height="27px">
      <Dropdown
        trigger={
          <EllipsisVerticalIcon
            data-testid="engagement-settings-menu"
            aria-label="Engagement Settings"
            className="h-3 w-3"
          />
        }
      >
        <DropdownItem onClick={onShowAllItems}>
          {shouldShowAllItems ? "Hide" : "Show"} Completed Items
        </DropdownItem>
        {onStatusUpdateClick && (
          <AccessRequired role={ROLES.ENGAGEMENT_MANAGER}>
            <DropdownItem onClick={onStatusUpdateClick}>
              Update Status
            </DropdownItem>
          </AccessRequired>
        )}
        <AccessRequired role={ROLES.ENGAGEMENT_MANAGER}>
          <DropdownItem onClick={onEditClick}>Project Settings</DropdownItem>
        </AccessRequired>
      </Dropdown>
    </Suspensed>
  );
};
