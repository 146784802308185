import { PropsWithChildren, useEffect } from "react";
import { useLocation, useMatch } from "react-router-dom";

import { SwitchCompanyResourceType } from "@m/api/public/types";
import { Logout, useAuth } from "@m/login";
import { UserSession } from "@m/types";
import { SpinnerScreen } from "@m/ui";

import { Company, useGetCompanies, useSwitchCompany } from "@mc/api";
import { BASE_PATHS } from "@mc/constants";
import { useNavigate } from "@mc/router";

export const CompanySwitcherProvider = ({ children }: PropsWithChildren) => {
  const { user } = useAuth();

  const {
    data: { companies },
    loading: getCompaniesLoading,
  } = useGetCompanies();

  const [
    switchCompany,
    { loading: switchCompanyLoading, called: switchCompanyCalled },
  ] = useSwitchCompany();

  const {
    companyId,
    path,
    shouldSwitchCompany,
    shouldUpdateCurrentPath,
    sysId,
  } = useSwitchCompanyLogic(user, companies, switchCompanyCalled);

  /** If a user does not have a company slug in local storage, log out to refresh  */
  if (!user?.company?.slug) return <Logout />;

  if (getCompaniesLoading || switchCompanyLoading) return <SpinnerScreen />;

  if (shouldSwitchCompany) {
    return (
      <SwitchCompany
        switchCompany={switchCompany}
        sysId={sysId}
        companyId={companyId}
      />
    );
  }

  if (shouldUpdateCurrentPath) return <UpdateCurrentPath path={path} />;

  return children;
};

const SwitchCompany = ({ switchCompany, sysId, companyId }) => {
  useEffect(() => {
    const { CompanySlug, SupportCase } = SwitchCompanyResourceType;

    switchCompany({
      resourceType: sysId ? SupportCase : CompanySlug,
      resourceId: sysId ?? companyId,
    });
  });

  return <SpinnerScreen />;
};

const UpdateCurrentPath = ({ path }) => {
  const navigate = useNavigate();

  useEffect(() => navigate(path, { replace: true }));

  return <SpinnerScreen />;
};

const useSwitchCompanyLogic = (
  user: UserSession,
  companies: Company[],
  switchCompanyCalled: boolean
) => {
  const { pathname } = useLocation();

  const companyIdMatch = useMatch("/:companyId/*");
  const caseDetailsMatch = useMatch("/:basePath/cases/:sysId");

  const companyId = companyIdMatch?.params?.companyId;
  const pathnameWithoutSlug = companyIdMatch?.params?.["*"];

  let sysId = caseDetailsMatch?.params?.sysId;

  // If the 'sysId' is actually just the 'create' route, set it
  // back to null so that we don't attempt a company switch
  // with it.
  if (sysId === "create") sysId = null;

  // A base path usually means that the user navigated to a path within mc directly
  // (e.g. /cloud-score). Less commonly, it can instead mean that the user navigated
  // to a support case url from service now (e.g. /dashboard/cases/:sysId)
  const isBasePath = BASE_PATHS.includes(companyId);
  const isCompanyMatch =
    companyId === user.company.slug || companyId === user.company.id;
  const isParamEmpty = companyId === undefined;
  const hasMultiCompanyAccess = companies.length > 1;

  const isValidCompanySlug = !isBasePath && !isCompanyMatch && !isParamEmpty;
  const isValidSupportCase = Boolean(sysId);

  // If the switch is blocked due to the user not having access to
  // multiple companies, the current location will still include the
  // invalid company slug. If this is the case, use the pathname without
  // the slug to get rid of the invalid slug.
  const isSwitchBlocked = isValidCompanySlug && !hasMultiCompanyAccess;
  const path = isSwitchBlocked ? pathnameWithoutSlug : pathname;

  const shouldSwitchCompany =
    hasMultiCompanyAccess &&
    !switchCompanyCalled &&
    (isValidCompanySlug || isValidSupportCase);

  const shouldUpdateCurrentPath = isParamEmpty || isBasePath || isSwitchBlocked;

  return {
    companyId,
    path,
    shouldSwitchCompany,
    shouldUpdateCurrentPath,
    sysId,
  };
};
