import { useMarketplaceRegistrations } from "@mc/features/Onboarding";

import { MarketplaceSubscriptionsEmpty } from "./MarketplaceSubscriptionsEmpty";
import { SubscriptionDetailsCard } from "./SubscriptionDetailsCard";

export const MarketplaceSubscriptionCards = () => {
  const { newRegistrations, startedRegistrations, onboardedRegistrations } =
    useMarketplaceRegistrations();

  const allRegistrations = [
    ...onboardedRegistrations,
    ...newRegistrations,
    ...startedRegistrations,
  ];

  if (allRegistrations.length === 0) return <MarketplaceSubscriptionsEmpty />;

  return (
    <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
      {[
        ...onboardedRegistrations,
        ...newRegistrations,
        ...startedRegistrations,
      ].map((subscription) => {
        const {
          awsAccountId,
          catalogItem: { displayName },
          expirationTime,
          iamExternalId,
          id,
          registrationStatus,
          onboardingStatus,
          subscribeTime,
        } = subscription;

        return (
          <SubscriptionDetailsCard
            key={id}
            expiresAt={expirationTime}
            iamExternalId={iamExternalId}
            registrationStatus={registrationStatus}
            onboardingStatus={onboardingStatus}
            productDisplayName={displayName}
            subscribedAccountId={awsAccountId}
            subscribedAt={subscribeTime}
          />
        );
      })}
    </div>
  );
};
