import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

import { AccessRequired } from "@m/api/components";
import { CaseState, CaseSubcategory, CaseWaitingOn } from "@m/api/public/types";
import {
  AccountAvatar,
  Badge,
  Button,
  CommentCount,
  Link,
  Search,
  SortDropdown,
  SpinnerScreen,
  Table,
  Timestamp,
  toast,
} from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { MUTATIONS, PATHS } from "@mc/constants";
import {
  CaseListError as ListError,
  CaseWaitingOnBadge as RequestWaitingOnBadge,
} from "@mc/features/SupportCases/components";
import {
  CASE_LIST_SOURCE as REQUEST_LIST_SOURCE,
  CASE_LIST_SOURCE_NAMES as REQUEST_LIST_SOURCE_NAMES,
} from "@mc/features/SupportCases/constants";
import { useUserPreference } from "@mc/hooks";
import { useNavigate } from "@mc/router";

import { useEngineerAssistRequests } from "../api";
import { EngineerAssistEmpty } from "../components";

export const RequestListPage = () => {
  const [query, setQuery] = useQueryParams({
    search: StringParam,
    sort: StringParam,
  });

  const {
    data: { requests },
    error,
    loading,
    pagination,
  } = useEngineerAssistRequests(query);

  if (error) {
    return <ListError />;
  }

  if (loading) {
    return <SpinnerScreen fitToParent={true} />;
  }

  const shouldShowEmptyState =
    // Empty state should not be shown if search query returns 0 results
    requests.length === 0 && !query.search;

  return (
    <Page
      title={
        <PageTitle
          actions={
            <AccessRequired mutation={MUTATIONS.CREATE_CASE}>
              <Button
                kind="primary"
                size="small"
                to={PATHS.ENGAGEMENTS_ENGINEER_ASSIST_CREATE}
              >
                New Request
              </Button>
            </AccessRequired>
          }
          title="Engineer Assist"
          padding="small"
        />
      }
      width="large"
    >
      {shouldShowEmptyState ? (
        <EngineerAssistEmpty />
      ) : (
        <EngineerAssistList
          requests={requests}
          query={query}
          loading={loading}
          pagination={pagination}
          setQuery={setQuery}
        />
      )}
    </Page>
  );
};

const EngineerAssistList = ({
  requests,
  query,
  loading,
  pagination,
  setQuery,
}) => {
  const [searchTerm, setSearchTerm] = useState(query.search);

  const navigate = useNavigate();

  const onSortChange = (sort) => {
    pagination.setCurrentPage(1);
    setQuery({
      sort,
    });
  };

  const handleSearchInputChange = (e) => {
    const {
      target: { value },
    } = e;

    if (value === "") setQuery({ search: undefined });

    setSearchTerm(value);
  };

  const handleSubmitSearch = (selectedSearch) => {
    if (selectedSearch.length < 3 && selectedSearch != "") {
      toast.error("Search must be at least 3 characters in length.");
      return;
    }

    setQuery({
      search: selectedSearch ?? undefined,
    });

    setSearchTerm(selectedSearch);
  };

  const listHeaders = {
    SUBJECT: { label: "Subject", sort: "NUMBER", width: "90%" },
    TYPE: { label: "Type", sort: "TYPE" },
    REPLIES: { label: "Replies", sort: "REPLIES" },
    OPENED: {
      label: "Opened",
      sort: "OPENED_AT",
      align: "right",
    },
  };

  const tableHeaders = Object.keys(listHeaders).map((accessor) => {
    const { label, sort, width, align } = listHeaders[accessor];

    return {
      label,
      accessor,
      sort,
      align,
      width,
    };
  });
  const rows = useMemo(
    () =>
      requests.map((requestDetails) => {
        const {
          state,
          source,
          sysId,
          shortDescription,
          openedAt,
          number,
          openedBy,
          subcategory,
          totalReplies,
        } = requestDetails;

        const requestDetailsPath = generatePath(
          PATHS.ENGAGEMENTS_ENGINEER_ASSIST_DETAILS,
          {
            sysId,
          }
        );

        const handleRowClick = () => navigate(requestDetailsPath);
        const subcategoryLabel =
          subcategory === CaseSubcategory.DevopsDesk ? "DevOps" : "AI";

        return {
          SUBJECT: (
            <RequestSubject
              state={state}
              shortDescription={shortDescription}
              number={number}
              openedBy={openedBy}
              source={source}
            />
          ),
          TYPE: (
            <Badge label={subcategoryLabel} size="small" status="default" />
          ),
          REPLIES: <CommentCount commentCount={totalReplies || 0} />,
          OPENED: (
            <Timestamp isoTime={openedAt} longFormat={true} titleCase={true} />
          ),
          onClick: handleRowClick,
        };
      }),
    [requests, navigate]
  );

  return (
    <div id="engineer-assist-list">
      <DetailsBanner />
      <div className="mb-2 flex items-center justify-between p-1">
        <div className="flex gap-4">
          <Search
            searchTerm={searchTerm}
            handleSearchInputChange={handleSearchInputChange}
            handleSubmitSearch={handleSubmitSearch}
          />
        </div>
        <SortDropdown sort={query?.sort} onSortChange={onSortChange} />
      </div>

      <Table
        onSortChange={onSortChange}
        ariaLabel={"Engineer Assist Request Table"}
        defaultSort={query?.sort || "OPENED_AT_DESC"}
        headers={tableHeaders}
        showHeader={false}
        loading={loading}
        rows={rows}
        wrapRowText={true}
        {...pagination}
      />
    </div>
  );
};

const DetailsBanner = () => {
  const [showEngineerAssistBanner, setShowEngineerAssistBanner] =
    useUserPreference<boolean>("showEngineerAssistBanner", true);

  const handleClose = () => setShowEngineerAssistBanner(false);

  if (!showEngineerAssistBanner) return null;

  return (
    <div className="mb-3 flex w-full flex-col gap-0.5 rounded-md bg-gray-50 p-3">
      <div className="flex items-center justify-between">
        <span className="font-semibold">Engineer Assist</span>
        <button
          onClick={handleClose}
          className="text-subdued"
          aria-label="Close Engineer Assist Details Banner"
        >
          <XMarkIcon className="h-2.5 w-2.5" />
        </button>
      </div>
      <span className="text-lg font-semibold">
        Your on-demand team for common everyday tasks in your AWS environment
      </span>
      <p className="py-1 text-subdued">
        Simply describe what you need help with and we’ll coordinate, estimate,
        and handle it with our AWS-certified engineers. Reach out to your
        Customer Success Manager or Account Executive for pricing.
      </p>
      <Link
        href={"https://www.missioncloud.com/engineer-assist"}
        target={"_blank"}
        rightIcon={ArrowRightIcon}
      >
        More details
      </Link>
    </div>
  );
};

export const RequestSubject = ({
  state,
  shortDescription,
  number,
  openedBy,
  source,
  waitingOn,
}: {
  state: string;
  shortDescription: string;
  number: string;
  openedBy: AccountAvatar;
  source: string;
  waitingOn?: CaseWaitingOn;
}) => {
  const openedByText =
    source !== REQUEST_LIST_SOURCE.MISSION &&
    source !== REQUEST_LIST_SOURCE.CUSTOMER &&
    source !== REQUEST_LIST_SOURCE.UNKNOWN
      ? REQUEST_LIST_SOURCE_NAMES[source]
      : openedBy?.name;

  return (
    <div
      data-testid="request-subject"
      className={clsx("block gap-2", {
        "opacity-50": state === CaseState.Closed,
      })}
    >
      <div className="mb-0.5 flex gap-2">
        <p className="font-semibold text-default">{shortDescription}</p>
        {state === CaseState.AwaitingInfo && (
          <RequestWaitingOnBadge waitingOn={waitingOn} />
        )}
        {state === CaseState.Closed && <RequestStatusBadge state={state} />}
      </div>
      <div className="flex gap-2">
        <p className="font-mono">{number}</p>
        <p>{openedByText}</p>
      </div>
    </div>
  );
};

const RequestStatusBadge = ({ state }: { state: string }) => {
  let statusText;

  switch (state) {
    case CaseState.OpenAssigned:
    case CaseState.OpenUnassigned:
    case CaseState.New:
    case CaseState.WorkInProgress:
    case CaseState.AwaitingInfo:
      statusText = "Open";
      break;
    case CaseState.Resolved:
    case CaseState.Closed:
    case CaseState.Cancelled:
      statusText = "Closed";
      break;
    default:
      break;
  }

  return (
    <Badge
      label={statusText}
      size="small"
      status={statusText === "Open" ? "active" : "default"}
    />
  );
};
