import { toast } from "@m/ui";

import { gql, useQuery } from "@mc/utils/graphql";

import { AWS_ACCOUNT_RESOURCES_MESSAGES } from "../constants";

export const AWS_ACCOUNT_RESOURCES = gql`
  query ResourceCounts($maxCount: Int) {
    applicationLoadBalancers: loadBalancers(
      loadBalancerType: "application"
      maxCount: $maxCount
    ) {
      totalCount
    }
    autoScalingGroups(maxCount: $maxCount) {
      totalCount
    }
    classicLoadBalancers(maxCount: $maxCount) {
      totalCount
    }
    ec2Instances(maxCount: $maxCount) {
      totalCount
    }
    ecsClusters(maxCount: $maxCount) {
      totalCount
    }
    eksClusters(maxCount: $maxCount) {
      totalCount
    }
    elasticacheClusters(maxCount: $maxCount) {
      totalCount
    }
    lambdaFunctions(maxCount: $maxCount) {
      totalCount
    }
    networkLoadBalancers: loadBalancers(
      loadBalancerType: "network"
      maxCount: $maxCount
    ) {
      totalCount
    }
    rdsInstances(maxCount: $maxCount) {
      totalCount
    }
    redshiftClusters(maxCount: $maxCount) {
      totalCount
    }
  }
`;

export const useAwsAccountResources = (variables = {}) => {
  const onError = () =>
    toast.error(AWS_ACCOUNT_RESOURCES_MESSAGES.GET_RESOURCES_FAILURE);
  const {
    loading,
    data = {},
    refetch,
  } = useQuery(AWS_ACCOUNT_RESOURCES, {
    onError,
    variables,
  });

  return {
    data,
    loading,
    refetch,
  };
};
