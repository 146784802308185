import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { ElasticacheClustersFilter } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { ELASTICACHE_LABEL, GET_RESOURCES_FAILURE } from "../constants";

export const GET_ELASTICACHE_CLUSTERS = gql(/* GraphQL */ `
  query ElasticacheClusters(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchTerm: String
    $sort: [ElasticacheClusterSortEnum]
    $where: ElasticacheClustersFilter
  ) {
    elasticacheClusters(
      after: $after
      before: $before
      first: $first
      last: $last
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          accountId
          accountName
          availabilityZone
          cacheClusterName
          createTime
          engine
          id
          isMissionManaged
          nodeType
          region
          status
          tagTotal
          tags
        }
      }
    }
  }
`);

export const useElasticacheClusters = (query) => {
  const where: ElasticacheClustersFilter = {};

  if (query.missionManaged === "Managed") where.isMissionManaged = true;
  if (query.missionManaged === "Unmanaged") where.isMissionManaged = false;
  if (query.accounts) where.accountIn = query.accounts;

  const onError = () => toast.error(GET_RESOURCES_FAILURE(ELASTICACHE_LABEL));

  const { data, loading, error, fetchMore } = useQuery(
    GET_ELASTICACHE_CLUSTERS,
    {
      variables: {
        sort: query.sort,
        ...INITIAL_PAGINATION,
        searchTerm: query.search,
        where,
      },
      onError,
      notifyOnNetworkStatusChange: true,
    }
  );

  const { edges, pageInfo, totalCount } = data?.elasticacheClusters || {};
  const clusters = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return { data: { clusters }, loading, error, pagination };
};
