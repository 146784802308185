import { useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { AccessRequired } from "@m/api/components";
import { ScorecardRecommendation } from "@m/api/public/types";
import { Button } from "@m/ui";
import { getMilliseconds } from "@m/utils";

import { PATHS } from "@mc/constants";

import { RecommendationStatusIcon } from "../icons";

interface Props {
  checkId: string;
  recommendations: Pick<
    ScorecardRecommendation,
    "id" | "updatedTime" | "scorecardRecStatus" | "title"
  >[];
}

export const CheckDetailsRecommendations = ({
  checkId,
  recommendations,
}: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const path = generatePath(PATHS._CLOUD_SCORE_CREATE_RECOMMENDATION, {
      checkId,
    });
    navigate("../" + path);
  };

  const sortedRecommendations = useMemo(() => {
    return recommendations.sort(
      (a, b) => getMilliseconds(b.updatedTime) - getMilliseconds(a.updatedTime)
    );
  }, [recommendations]);

  return (
    <div
      className="flex flex-col gap-2"
      data-testid="check-details-recommendations"
    >
      <div className="flex flex-col gap-0.5">
        <div className="text-xs font-semibold text-default">
          Recommendations
        </div>
        <div className="flex flex-col gap-1 text-sm font-semibold text-default">
          {recommendations.length < 1
            ? "No Recommendations"
            : sortedRecommendations.map((recommendation) => (
                <RecommendationListItem
                  key={recommendation.id}
                  recommendation={recommendation}
                />
              ))}
        </div>
      </div>
      <AccessRequired mutation="createScorecardRecommendation">
        <Button onClick={handleClick}>Create Recommendation</Button>
      </AccessRequired>
    </div>
  );
};

const RecommendationListItem = ({
  recommendation,
}: {
  recommendation: Pick<
    ScorecardRecommendation,
    "scorecardRecStatus" | "title" | "id"
  >;
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const path = generatePath(PATHS._CLOUD_SCORE_RECOMMENDATION_DETAILS, {
      recommendationId: recommendation.id,
    });
    navigate("../" + path);
  };

  return (
    <button
      className="flex items-center gap-1 text-left"
      data-testid="check-details-recommendation"
      key={recommendation.id}
      onClick={handleClick}
    >
      <RecommendationStatusIcon status={recommendation.scorecardRecStatus} />
      <div>{recommendation.title}</div>
    </button>
  );
};
