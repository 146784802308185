import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { MouseEventHandler } from "react";

import { AccessRequired } from "@m/api/components";

interface Props {
  disabled?: boolean;
  ignore: boolean;
  onClick: MouseEventHandler;
}

export const IgnoreCheckButton = ({
  disabled = false,
  ignore,
  onClick,
}: Props) => (
  <AccessRequired mutation="updateCheckSourceIgnore">
    <button
      className={clsx("mt-0.5 flex space-x-1", {
        "text-action hover:text-blue-500": !disabled,
        "text-gray-400": disabled,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      {ignore ? (
        <EyeSlashIcon className="h-2.5 w-2.5" />
      ) : (
        <EyeIcon className="h-2.5 w-2.5" />
      )}
      <div className="text-sm font-semibold">
        {ignore ? "Ignore" : "Monitor"} Check
      </div>
    </button>
  </AccessRequired>
);
