import { ACTIVITY_FEED_GROUPS, ActivityFeed } from "@mc/features/ActivityFeed";

import { useScorecardActivityStream } from "../api";
import { getScoreChanges } from "../utils";

export const ScorecardActivityFeed = ({
  // This prop is included to help make unit test consistency a bit easier
  // by allowing us to directly including a static value for group dates
  activityFeedGroups = ACTIVITY_FEED_GROUPS,
}: {
  activityFeedGroups?: {
    id: string;
    title: string;
    startTime: string;
    endTime: string;
  }[];
}) => {
  const {
    data: { activities, scorecards },
    loading,
    error,
  } = useScorecardActivityStream();

  const changes = getScoreChanges(scorecards);

  return (
    <ActivityFeed
      activities={activities}
      loading={loading}
      error={error}
      changes={changes}
      itemTitleColor="action"
      activityFeedGroups={activityFeedGroups}
    />
  );
};
