import { AccessRequired } from "@m/api/components";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { FEATURE_FLAGS } from "@mc/constants";

import {
  MarketplaceSubscriptionCards,
  MeteredChargesTable,
} from "../components";

export const MarketplaceSubscriptionsPage = () => (
  <Page data-testid="marketplace-subscriptions-page">
    <PageHeading heading="Subscriptions" />
    <MarketplaceSubscriptionCards />
    <AccessRequired
      feature={FEATURE_FLAGS.SETTINGS_ROLLOUT_SUBSCRIPTION_DETAILS_PAGE}
    >
      <MeteredChargesTable />
    </AccessRequired>
  </Page>
);
