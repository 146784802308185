import { dt } from "@m/utils";

export const rightNow = () => dt.utc().toISO();
export const oneDayAgo = () => dt.utc().minus({ days: 1 }).toISO();
export const twoDaysAgo = () => dt.utc().minus({ days: 2 }).toISO();
export const oneWeekAgo = () => dt.utc().minus({ weeks: 1 }).toISO();
export const twoWeeksAgo = () => dt.utc().minus({ weeks: 2 }).toISO();
export const thirtyDaysAgo = () => dt.utc().minus({ days: 30 }).toISO();
export const oneHundredTwentyDaysAgo = () =>
  dt.utc().minus({ days: 120 }).toISO();

export const startOfCurrentWeek = () => dt.utc().startOf("week").toISO();
export const startOfCurrentMonth = () => dt.utc().startOf("month").toISO();
export const startOfLastMonth = () =>
  dt.utc().startOf("month").minus({ month: 1 }).toISO();
