import { ErrorBoundary } from "@sentry/react";
import { Interweave, Matcher } from "interweave";
import {
  EMAIL_PATTERN,
  EmailMatcher,
  URL_PATTERN,
  UrlMatcher,
} from "interweave-autolink";
import PT from "prop-types";

import { Link } from "@m/ui";

const AMAZON_INC = "Amazon.com";

function CaseLink({ children, url, newWindow }) {
  const content = children ?? url.replace("mailto:", "");
  const href =
    url.match(/^https?:\/\//) || url.startsWith("mailto:")
      ? url
      : `http://${url}`;
  return content ? (
    <Link
      href={href}
      target={newWindow ? "_blank" : undefined}
      className={content.startsWith("http") && "break-all"}
    >
      {content}
    </Link>
  ) : null;
}
CaseLink.propTypes = {
  children: PT.string.isRequired,
  url: PT.string.isRequired,
  newWindow: PT.bool,
};

function CaseEmail({ email, ...props }) {
  return <CaseLink url={`mailto:${email}`} {...props} />;
}
CaseEmail.propTypes = {
  email: PT.string.isRequired,
};

class AwsUrlMatcher extends Matcher {
  // AWS urls are tricky to parse. For example, Interweave's built-in
  // parsers are sometimes confused by the fact that aws urls can
  // have a separate set of query params in their url fragment.
  // If we detect a link in the aws domain, assume _everything_
  // after the scheme is part of the url until we reach a space.
  static count = 0;
  static pattern = /https?:.+?aws.amazon.com[^\s\]]+/;

  match(string) {
    const result = string.match(this.constructor.pattern);
    if (!result) return null;

    const url = result[0];
    const index = result.index;
    // Skip this url if it looks like it is already part of a bracket link
    if (string.substring(index).startsWith(`${url} [${url}]`)) return null;
    if (string.indexOf(`[${url}]`) > 1 && string.indexOf(`[${url}]`) < index)
      return null;

    return {
      index,
      length: url.length,
      match: url,
      valid: true,
      content: url,
      url,
    };
  }
  replaceWith(children, { content, url }) {
    const key = `${this.propName}-${this.constructor.count++}`;
    return (
      <CaseLink key={key} url={url}>
        {content}
      </CaseLink>
    );
  }

  asTag() {
    return "a";
  }
}

class BracketLinkMatcher extends Matcher {
  // A bracketed link is any url or mail address in square brackets.
  // The link content is any title-cased text immediately preceding the
  // brackets, e.g. "Mission Control [https://control.missioncloud.com]"
  // If no title-cased text precedes the brackets, the link text is the
  // first space-delimited word preceding the brackets, e.g. the "here"
  // in "click here [https://amazon.aws.com]"
  static count = 0;
  static pattern = /\[\s?https?:.+?]|\[\s?mailto:.+?\]/;

  match(string) {
    const result = string.match(this.constructor.pattern);
    if (!result) {
      return null;
    }
    let url = result[0].trimEnd();
    if (url.startsWith("[")) url = url.slice(1);
    if (url.endsWith("]") && !url.endsWith("[]")) url = url.slice(0, -1);

    // Get space-delimited words leading up to the match
    const prior = string.substring(0, result.index).trimEnd().split(" ");
    // Build up content as the title-cased text imediately preceding the
    // match or just the first word if there is no title-cased text
    const contentWords = [];
    for (let i = prior.length - 1; i >= 0; i--) {
      const word = prior[i];
      const isTitleCased =
        word.length >= 2 && word[0] === word[0].toUpperCase();
      if (!isTitleCased) {
        if (i === prior.length - 1) {
          contentWords.unshift(word);
        }
        break;
      }
      contentWords.unshift(word);
    }
    let content = contentWords.join(" ");

    // If the preceding words contain an email address, don't include them
    if (EMAIL_PATTERN.exec(content)) content = "";
    // If the preceding words contain a url, don't include them
    if (URL_PATTERN.exec(content)) content = "";

    return {
      index: result.index - content.length - 1,
      length: result[0].length + content.length + 1,
      match: content + " " + result[0],
      valid: true,
      content,
      url,
    };
  }

  replaceWith(children, { content, url }) {
    const urlKey = `${this.propName}-${this.constructor.count++}`;
    return (
      <CaseLink key={urlKey} url={url}>
        {content}
      </CaseLink>
    );
  }

  asTag() {
    return "a";
  }
}

class CaseLinkMatcher extends UrlMatcher {
  doMatch(string, pattern, callback, isVoid) {
    const result = super.doMatch(string, pattern, callback, isVoid);
    if (result?.match === AMAZON_INC) {
      // Don't auto-convert the "Amazon.com" in "Amazon.com, Inc." from aws
      // email signatures to links
      return null;
    }
    return result;
  }
}

function caseTransform(node, children) {
  if (node.tagName.toLowerCase() === "a") {
    let href = node.getAttribute("href");
    if (href?.startsWith("/sn_") && process.env.REACT_APP_SNOW_DOMAIN) {
      // We assume that any link with a relative path pointing to /sn_*
      // is an embedded link from service now that was originally intended
      // to be clicked from within the service now webpage. These links won't
      // work when clicked from missioncontrol because they're relative to the
      // domain of the service now instance that produced the email event.
      // We fix them here by prepending the service now domain to make the
      // path absolute.
      href = `https://${process.env.REACT_APP_SNOW_DOMAIN}${href}`;
    }
    return <Link href={href}>{children}</Link>;
  }
}

export function HTMLContent({
  children,
  className = "space-y-1.5",
  containerTagName = "div",
  groupKey,
  noWrap,
}) {
  const content = children
    .split(/\r?\n\r?\n/g)
    .map((line, i) => `<p key="${i}-${groupKey ?? ""}">${line}</p>`)
    .join("")
    .split(/\r?\n/g)
    .join("<br>");

  return (
    <ErrorBoundary fallback={<>{children}</>}>
      <Interweave
        className={className}
        containerTagName={containerTagName}
        content={content}
        noWrap={noWrap}
        transform={caseTransform}
        transformOnlyAllowList={true}
        matchers={[
          new AwsUrlMatcher("aws"),
          new BracketLinkMatcher("bracketLink"),
          new EmailMatcher("email", {}, CaseEmail),
          new CaseLinkMatcher("url", { customTLDs: ["aws"] }, CaseLink),
        ]}
      />
    </ErrorBoundary>
  );
}
HTMLContent.propTypes = {
  children: PT.oneOfType([PT.string, PT.oneOf([null])]),
  className: PT.string,
  containerTagName: PT.string,
  groupKey: PT.string,
  noWrap: PT.bool,
};
