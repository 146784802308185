import {
  ArrowUpRightIcon,
  BookOpenIcon,
  ClipboardDocumentListIcon,
  LifebuoyIcon,
  LightBulbIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";

import { useAccessRequired } from "@m/api/components";
import { Dropdown, DropdownGroup, DropdownItem } from "@m/ui";

import {
  KNOWLEDGE_BASE_URL,
  MARKETPLACE_KNOWLEDGE_BASE_URL,
  RELEASE_NOTES_URL,
  SKUS,
} from "@mc/constants";
import { useUsersnapEvent } from "@mc/providers/UsersnapProvider";

export const SupportDropdown = () => {
  const [openFeedbackForm, isUsersnapLoaded] =
    useUsersnapEvent("manual-feedback");

  const isCloudScoreUser = useAccessRequired({
    entitlement: SKUS.CLOUD_SCORE,
  });

  const [openSupportForm] = useUsersnapEvent("app-support-form");

  const handleClickFeedback = () => openFeedbackForm();
  const handleClickHelp = () => openSupportForm();

  const handleClickReleaseNotes = () => {
    window.open(RELEASE_NOTES_URL, "_blank");
  };

  const handleClickKnowledgeBase = () => {
    const knowledgeBaseUrl = isCloudScoreUser
      ? MARKETPLACE_KNOWLEDGE_BASE_URL
      : KNOWLEDGE_BASE_URL;
    window.open(knowledgeBaseUrl, "_blank");
  };

  return (
    <Dropdown
      direction="bottom-end"
      trigger={
        <QuestionMarkCircleIcon
          className="h-3 w-3 cursor-pointer text-accent hover:opacity-80"
          data-testid="support-dropdown-trigger"
        />
      }
    >
      <DropdownGroup>
        <DropdownItem icon={BookOpenIcon} onClick={handleClickKnowledgeBase}>
          Knowledge Base
          <ArrowUpRightIcon className="ml-1 h-1.5 w-1.5 text-subdued" />
        </DropdownItem>
        <DropdownItem
          icon={ClipboardDocumentListIcon}
          onClick={handleClickReleaseNotes}
        >
          Release Notes
          <ArrowUpRightIcon className="ml-1 h-1.5 w-1.5 text-subdued" />
        </DropdownItem>
      </DropdownGroup>

      <DropdownGroup>
        <DropdownItem
          icon={LifebuoyIcon}
          onClick={handleClickHelp}
          disabled={!isUsersnapLoaded}
        >
          Application Help
        </DropdownItem>
        <DropdownItem
          icon={LightBulbIcon}
          onClick={handleClickFeedback}
          disabled={!isUsersnapLoaded}
        >
          Feedback
        </DropdownItem>
      </DropdownGroup>
    </Dropdown>
  );
};
